@import '../chrome/styles/variables';
.container {
  width: 100%;
  height: 100vh;
  overflow-y: auto;
  z-index: 200;
  padding: 25px;
  h2,
  h4 {
    margin-bottom: 25px;
  }
  .section {
    display: flex;
    flex-direction: column;
    align-items: center;
    .flexColumn {
      display: inline-flex;
      flex-direction: column;
    }
  }
  .importArea {
    width: 300px;
    margin: 25px auto;
  }
  .importButton {
    margin-top: 25px;
  }
  .actions {
    display: flex;
    justify-content: space-between;
  }
  .contactTable {
    display: inline-block;
    padding-left: 25px;
    padding-right: 25px;
  }
  .resultButtons {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 768px) {
  .headerMap {
    flex-wrap: wrap;
    .headers {
      width: 100% !important;
    }
    .preview {
      margin-left: 0px !important;
    }
  }
}

@media (max-width: 350px) {
  .resultButtons {
    flex-wrap: wrap;
    .toCatalogBtn {
      margin-top: 15px;
      margin-left: 0px !important;
    }
  }
}

.customDropzone {
  height: 200px;
  background: #ececec;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1.5px dashed $pink;
  border-radius: 5px;
  cursor: pointer;
}
