.cb {
  input {
    position: absolute;
  }
}

@media (max-width: 600px) {
  .content {
    height: 100vh;
    transform: initial !important;
    width: 100% !important;
    max-width: 100% !important;
  }
}
