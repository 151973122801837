.inlineBlock {
  display: inline-block;
}
.imageAsset {
  position: relative;
  cursor: pointer;
  display: inline-block;
  &.imageSquare {
    &:after {
      content: '';
      padding-bottom: 85%;
      display: block;
    }
  }

  &:hover {
    .deleteButton {
      opacity: 1;
    }
  }
  .backgroundImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50%;
    border-radius: 16px;
  }
  .deleteButton {
    position: absolute;
    top: -3px;
    right: -3px;
    cursor: pointer;
  }
  .deleteButtonDesktop {
    opacity: 0;
  }
}
