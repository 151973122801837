.container {
  display: flex;
  flex-flow: row wrap;
  .fields {
    flex-grow: 2;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-around;
    align-items: flex-start;
  }
  .buttons {
    margin-top: 1rem;
    flex-grow: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
  }
}
