.confirmMessage {
  padding-left: 0 !important;
  padding-right: 0 !important;
  @media (max-width: 600px) {
    height: calc(100vh - 76px);
  }
}

.confirmWrapper {
  z-index: 1;
}
