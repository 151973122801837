.tableInfo {
  font-size: 1.8rem;
  padding: 0 25px 10px 25px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.tableContainer {
  overflow-y: auto;
  padding: 0 15px;
}
.footer {
  width: 99%;
  display: flex;
  justify-content: center;
}
.cardImageHorizontal {
  min-height: 400px;
  height: 400px;
  width: auto !important;
  @media (max-width: 768px) {
    min-height: 300px;
    height: 300px;
  }
  @media (max-width: 516px) {
    min-height: 200px;
    height: 200px;
  }
}
.cardImageVertical {
  min-height: 450px;
  height: 450px;
  width: auto !important;
  @media (max-width: 768px) {
    min-height: 350px;
    height: 350px;
  }
}
.confirmContainerHorizontal {
  width: 665px;
  @media (max-width: 768px) {
    width: 525px;
  }
  @media (max-width: 516px) {
    width: 100%;
    height: 100%;
  }
}

.confirmContainerVertical {
  width: 450px;
  @media (max-width: 768px) {
    width: 400px;
  }
  @media (max-width: 516px) {
    width: 100%;
    height: 100%;
  }
}
.confirmWrapper {
  z-index: 3001;
}

.confirmActions {
  @media (max-width: 516px) {
    justify-content: space-between;
    width: 100%;
  }
}
.searchBar {
  .contextSwitch {
    position: absolute;
    top: 10px;
    right: 55px;
    button {
      color: #6f8394 !important;
    }
    @media (max-width: 1400px) {
      top: 84px;
    }
    @media (max-width: 805px) {
      top: 92px;
    }
  }
}
.customEditBtn {
  position: absolute;
  bottom: 25px;
  left: 50%;
  transform: translate(-50%, -50%);
}
