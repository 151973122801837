.cardList {
  display: flex;
  flex-direction: column;
  row-gap: 2rem;
  overflow-x: auto;
  column-gap: 2rem;
  grid-template-columns: 1fr 1fr;
}

.cardList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.cardList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.cardList::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.cardList::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
