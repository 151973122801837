@import '../../../chrome/styles/variables';

// Mixins
@mixin mq($width) {
  @media screen and (max-width: $width) {
    @content;
  }
}

.editorContextBar {
  width: calc(100vw - 65px);
  position: absolute;
  left: 65px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 100px;
  h4 {
    text-transform: capitalize !important;
  }
  @media (max-width: 568px) {
    width: 100vw;
    left: 0;
  }
  @media (max-width: 568px) {
    padding-top: 70px;
  }
}

:global {
  #openFilters {
    display: block;
  }
}
.closeDrawerScreen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: transparent;
}
.flexButton {
  display: flex;
  justify-content: center;
  margin-top: 28px;
}
.buttonFlexBlock {
  display: flex;
  justify-content: center;
  padding-bottom: 15px;
  padding-top: 15px;
}
.Loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
// Drawer Triggers
.launchImageDrawer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 45px;
  height: 45px;
  background-image: linear-gradient(115deg, #ff5689, #e687ff);
  box-shadow: 0 1px 15px 0 rgba(239, 146, 251, 0.3);
  z-index: 9000;
  text-align: center;
  border-radius: 100px;
  cursor: pointer;
  &:before {
    width: 0;
    height: 0;
    position: absolute;
    z-index: 1;
    border-width: 18px;
    border-style: solid;
    border-color: transparent;
    border-left-color: inherit;
    right: -28px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
  }
  svg {
    fill: #fff;
    width: 31px;
    height: 17px;
    position: absolute;
    top: 14px;
    left: 6px;
    pointer-events: none !important;
  }
}
// Drawer Styles
.textDrawer {
  display: block;
  position: fixed;
  bottom: 0;
  left: 65px;
  width: calc(100vw - 60px);
  height: auto;
  background-color: #fff;
  z-index: 100;
  border-top: 1px solid #e6e4e4;
}
.ImageUploading {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  img {
    width: 60px;
    height: 40px;
    max-width: 60px;
    max-height: 40px;
    box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.175);
    margin-right: 20px;
  }
}
.imageDrawer {
  display: block;
  position: fixed;
  bottom: 0;
  left: 65px;
  width: calc(100vw - 60px);
  display: flex;
  flex-direction: column;
  height: auto;
  background: #fff;
  z-index: 110;
  border-top: 1px solid #e6e4e4;
  @media (min-width: 569px) {
    width: 325px;
    height: 100vh;
    left: 67px;
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 50px;
    box-shadow: 0 1px 3px rgba(163, 163, 163, 0.12),
      0 1px 2px rgba(0, 0, 0, 0.24);
    .imagePanelNav {
      height: 65px;
    }
    .images {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: flex-start;
      align-content: flex-start;
      img,
      .layoutContainer {
        width: 120px;
        height: auto;
        max-height: 170px;
        margin-bottom: 5px;
        box-shadow: 0 1px 6px -1px #a8a8a8;
      }
    }
    .ImageFilters {
      width: 100%;
      height: 100vh;
      .Settings {
        width: 100%;
        height: 100%;
      }
      .FilterList {
        height: 100%;
        flex-wrap: wrap;
        background-color: #fff;
        .Filter {
          width: calc(50% - 10px);
          height: 10%;
          min-width: initial;
          margin-right: 10px;
          margin-bottom: 40px;
        }
      }
    }
    .drawerBarTop {
      margin: 0;
      span {
        display: block;
        margin: 15px;
        font-weight: 400;
      }
    }
    .drawerBarBottom {
      margin: 0 !important;
      position: fixed !important;
      width: 315px !important;
      left: 65px !important;
      bottom: 0px;
      height: 50px;
      font-size: 10px !important;
      border-top: 1px solid #e7e7e7;
      background: #fff;
    }
    .layouts {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    &.fullDrawer {
      width: calc(100vw - 65px);
      background: #f7f7f7;
    }
  }

  .imagePanelNav {
    width: 100%;
    height: 43px;
    border-bottom: 1px solid #f1f1f1;
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    > li {
      width: 50%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 13px;
      font-weight: 500;
      text-transform: capitalize;
      cursor: pointer;
      &.selected,
      &:hover {
        color: #ff5689;
        border-bottom: 1px solid #ff5689;
      }
    }
  }
  .images {
    padding: 0 10px;
    overflow-x: hidden;
    overflow-y: auto;
    height: 100%;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    white-space: nowrap;
    width: 100%;
    @media (max-width: 569px) {
      overflow-x: auto;
      overflow-y: hidden;
      height: auto;
    }
    // Images
    img,
    .layoutContainer {
      cursor: pointer;
      margin: 5px;
      box-shadow: 0 1px 6px -1px #a8a8a8;
    }
  }
  .drawerTitle {
    display: flex !important;
    align-items: center;
    justify-content: space-between;
    .drawerClose {
      margin-left: auto;
    }
  }
  .drawerBarTop,
  .drawerBarBottom {
    position: relative;
    font-size: 13px;
    color: #333333;
    svg {
      position: initial;
      width: 16px;
      fill: $purple;
      margin-right: 3px;
    }
  }
  .drawerBarTop {
    z-index: 100;
  }
  .drawerBarBottom {
    margin: 12px 15px;
    .uploadIcon {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 100%;
      padding-top: 0;
      padding-left: 20px;
      padding-right: 40px;
      display: flex;
      justify-content: center;
      > div {
        display: flex;
        width: 100%;
      }
      svg {
        width: 30px;
        height: 20px;
      }
    }
  }
}
// Override gradient button for upload image trigger
.uploadImage {
  height: 27px !important;
  width: 135px !important;
  span {
    line-height: 25px !important;
  }
}
// Image Filter Styles
.ImageFilters {
  position: relative;
  top: 0;
  left: 0;
  width: 100vw;
  height: 170px;
  background: #fff;
  @media (max-width: 414px) {
    top: 3px;
  }
  @media (min-width: 569px) {
    width: 315px;
    height: calc(100vh - 48px);
    overflow-y: hidden;
    overflow-x: hidden;
    padding-bottom: 100px;
    .images {
      width: 100%;
      height: 100%;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      img,
      .layoutContainer {
        width: 120px;
        height: auto;
        max-height: 170px;
        margin-bottom: 5px;
        box-shadow: 0 1px 6px -1px #a8a8a8;
      }
    }
    .drawerBarBottom {
      bottom: 0;
      left: 0;
      height: 40px;
      width: 100%;
      font-size: 10px;
      border-top: 1px solid #f1f1f1;
    }
  }
}

.Templates {
  background-size: cover;
  display: inline-block;
  width: 125px;
  height: 175px;
  margin-top: 20px;
  margin-right: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  -webkit-transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  transition: all 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
  cursor: pointer;
  &:hover {
    box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
  }
}

.Settings {
  width: calc(100vw - 65px); //sidebar width
  position: relative;
  height: 250px;
  z-index: 2;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;
}

.FilterList {
  width: 100%;
  height: 200px;
  background: #f0f0f0;
  box-sizing: border-box;
  padding: 20px;
  display: flex;
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
  -ms-overflow-style: -ms-autohiding-scrollbar;

  .Filter {
    min-width: 150px;
    width: 15%;
    height: 70%;
    position: relative;
    flex: 1 0 1;
    margin-right: 20px;
    box-shadow: 0 10px 12px 0 rgba(140, 140, 140, 0.25);
    border-radius: 5px;
    transition: color, box-shadow 0.25s ease;
    font-size: 13px;
    color: #6f8394;
    &:hover {
      cursor: pointer;
      box-shadow: 0 10px 20px 0 rgba(black, 0.25);
    }

    h5 {
      position: absolute;
      top: 100%;
      left: 50%;
      transform: translateX(-50%);
      margin-top: 12px;
      text-transform: uppercase;
      font-size: inherit;
      font-weight: inherit;
    }
    // FilterImage
    .Image {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-size: cover;
      background-position: center center;
      border-radius: 5px;
      transition: filter 0.25s ease 0.25s;
    }
  }
}
//Upload Styles
.hideImageUpload {
  display: none;
}
.choices {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 10px;
  h3 {
    margin-bottom: 20px;
    width: 100%;
  }
  a {
    margin-top: 50px;
    margin-left: 20px;
  }
}
@media (max-height: 767px) {
  .editorContextBar {
    h4 {
      font-size: 16px;
      line-height: 12px;
    }
    p {
      font-size: 16px;
    }
  }
}
@media (max-width: 568px) {
  .editorContextBar {
    padding-top: 10px;
  }
  .hideToolBar {
    bottom: 0;
  }
  .textDrawer {
    left: 0;
    width: 100%;
  }
  .imageDrawer {
    width: 100%;
    left: 0;
    bottom: 0px;
    z-index: 1000;
    padding: 0 0 65px 0;
    text-align: center;
    .drawerBarBottom {
      padding-bottom: 20px;
    }
    .drawerBarBottom.layout {
      padding-bottom: 0px;
    }
  }
  .layouts {
    display: flex;
    overflow-x: auto;
  }
  .drawerBarTop {
    .drawerTitle {
      position: relative;
      bottom: 5px;
      padding-top: 7px;
      display: block;
      padding: 10px;
      > span {
        font-weight: 500;
        font-size: 0.95em;
      }
    }
  }
  .launchImageDrawer {
    width: 35px;
    height: 36px;
    &:before {
      border-width: 12px;
      right: -20px;
    }
    svg {
      width: 24px;
      height: 15px;
      top: 10px;
      left: 5px;
    }
  }
  .Settings {
    width: 100%;
    height: 175px;
  }
  .FilterList {
    height: 175px;
    background: #fff;
    .Filter {
      transition: none;
      &:hover {
        transform: none;
      }

      h5 {
        margin-top: 9px;
        font-size: 12px;
        font-weight: normal;
      }
    }
  }
}

.backTemplates {
  padding-top: 25px;
}
.layoutContainer {
  .defaultLayout {
    border-color: #14f243;
    border-width: 3px;
    border-style: solid;
    border-radius: 2px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 1px 18px -1px #a8a8a8;
    cursor: pointer;
  }
  .defaultLabel {
    color: white;
    background-color: #14f243;
    position: absolute;
    top: 0;
    left: 29px;
    width: 54px;
    text-align: center;
    border-radius: 2px;
    border-top-left-radius: 0px;
    border-top-right-radius: 0px;
    box-shadow: 0 1px 18px -1px #a8a8a8;
  }
  .defaultButton {
    opacity: 0;
    position: absolute;
    bottom: 10px;
    left: 12px;
    background-color: #f759a2;
    color: white;
    width: 95px;
    text-align: center;
    font-weight: 900;
    border-radius: 4px;
    padding: 3px;
    cursor: pointer;
    font-weight: 200;
  }
  &:hover {
    .defaultButton {
      opacity: 1;
    }
  }
}
