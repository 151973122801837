// CARD LOGO SVG STYLES
.logoPlacement {
  position: relative;
  margin-top: -20px;
  left: 195px;
}

.cls1 {
  fill: none;
}

.cls2 {
  fill: #fff;
}

.cls3 {
  fill: #ff5f00;
}

.cls4 {
  fill: #eb001b;
}

.cls5 {
  fill: #f79e1b;
}
