.campaignWrapper {
  width: 100%;
  overflow-x: hidden;
  height: 100vh;
  .header {
    background-color: #3a474e;
    width: 100%;
    height: 144px;
    position: sticky;
    top: 0;
    z-index: 100;
    h1 {
      color: #fff;
      position: relative;
      font-size: 26px;
      top: 60px;
      left: 34px;
      font-weight: 400;
      letter-spacing: 2px;
    }
  }
  .search {
    top: 8px;
    z-index: 101;
  }
  .campaignTabs {
    width: 145px !important;
  }
  .addCampaign {
    position: relative;
    left: 90%;
    top: 65px;
    z-index: 102;
    > button {
      background-image: linear-gradient(115deg, #ff5689, #e687ff) !important;
    }
    @media (max-width: 537px) {
      position: fixed;
      top: unset;
      bottom: 85px;
      right: 6%;
    }
  }
}

@media (max-width: 568px) {
  .campaignWrapper {
    .header {
      height: 100px;
      h1 {
        top: 10px;
        left: 22px;
        line-height: initial;
      }
    }
  }
}
