$font-stack: 'Open Sans', sans-serif;

.modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(48, 57, 63, 0.88);
  z-index: 104;
}
.modalBody {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: fixed;
  overflow-x: auto;
  top: 50%;
  left: 50%;
  transform: translate(calc(-50% - -65px), -50%);
  z-index: 105;
  background: #fff;
  width: 920px;
  .blankCardOptions {
    display: flex;
    position: relative;
    width: 100%;
    cursor: pointer;
    padding: 35px 0;
    justify-content: center;
    align-items: center;
    img {
      width: 100%;
    }
    .portrait,
    .landscape {
      &:after {
        width: 100%;
        text-align: center;
        font-family: sans-serif;
        font-size: 15px;
        font-weight: bold;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        position: absolute;
      }
    }
    .portrait {
      width: 200px;
      height: 280.48px;
      box-shadow: 0 1px 5px 0 #d4d4d4;
      margin-right: 35px;
      cursor: pointer;
      position: relative;
      &:after {
        content: 'Your Photo Here';
      }
    }
    .landscape {
      width: 280px;
      height: 200px;
      box-shadow: 0 4px 5px 0 #d4d4d4;
      cursor: pointer;
      position: relative;
      &:after {
        content: 'Your Photo Here';
      }
    }
  }
  .modalContent {
    text-align: center;
    width: 100%;
    padding: 35px;
  }
}

.close {
  position: absolute;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .modalBody {
    .card {
      .portrait {
        width: 340px;
        height: 470px;
      }
      .landscape {
        height: 340px;
        width: 470px;
      }
    }
  }
}
@media (max-width: 991px) {
  .modalBody {
    width: calc(100vw - 95px) !important;
    left: 80px;
    top: 25%;
    transform: none;
    .modalContent {
      padding: 15px 0;
    }
  }
}
@media (max-width: 756px) {
  .modalBody {
    top: 25%;
    left: 50px;
    .blankCardOptions {
      .portrait {
        width: 150px;
        height: 215.48px;
      }
      .landscape {
        width: 215.48px;
        height: 150px;
      }
    }
  }
}
@media (max-width: 568px) {
  .modalBody {
    width: 100vw !important;
    height: 100vh;
    max-width: initial;
    left: 0;
    top: 0;
    transform: none;
    .blankCardOptions {
      transform: scale(0.85);
    }
  }
}
