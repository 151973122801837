.container {
  margin: 20px 50px 0;
}
.actions {
  .desktopActions {
    margin: 0px -10px;
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
    button {
      margin: 0px !important;
    }
  }
  .desktopActions > div {
    margin: 10px;
  }
}
.contextDetails {
  display: flex;
}

.hide,
.showSm {
  display: none !important;
}

.mobileItems,
.sendMobile,
.selectAllMobile {
  display: none !important;
}
@media (min-width: 784px) {
  .contextDetails {
    padding-top: 20px;
  }
}

@media (max-width: 1110px) {
  .mobileItems,
  .onlyMd {
    display: block !important;
  }
  .showMd {
    display: block !important;
  }
}

@media (max-width: 783px) {
  .actions {
    text-align: right;
    .desktopActions {
      display: none !important;
    }
  }
  .contextDetails {
    padding: 10px;
  }
  .sendMobile,
  .selectAllMobile,
  .onlySm {
    display: block !important;
  }
  .showSm {
    float: right;
    display: block !important;
  }
}
@media (max-width: 568px) {
  .container {
    margin: 20px 15px 0;
  }
}
