.searchField {
  max-width: 900px;
  margin: 0 auto;
  position: relative;
  padding-bottom: 35px;
}

//Search Tags
.searchTags {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden !important;
  justify-content: space-evenly;
  > div {
    flex: 0 0 auto;
    padding-bottom: 10px;
    cursor: pointer;
  }
}
.buildYourOwn {
  width: 160px !important;
  height: 40px !important;
}
.filterMenu {
  position: absolute !important;
  right: 0;
  top: 0;
  height: 45px !important;
  background-color: #fff;
  box-shadow: 0 4px 2px -2px #d4d4d4;
  @media (max-width: 400px) {
    width: 100% !important;
    top: 46px;
  }
}
// Search Bar
.search {
  position: relative;
  display: flex;
  align-items: center;
  background: #f5f6f7;
  padding: 8px;
  margin: 5px 35px;
  border-radius: 2px;
  height: 40px;
  border-radius: 10px;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  &:focus-within {
    box-shadow: 0 0 0 1px #e841b3;
  }
  .selectedTag {
    background: linear-gradient(115deg, #e687ff, #ff5689);
    color: #e687ff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 9px;
    font-weight: 500;
    flex: 0 0 auto;
  }
  input[type='text'] {
    height: auto;
    flex: 1 1 auto;
    position: relative;
    padding: 0 10px 0 9px;
    background-color: transparent;
    border: none;
    color: #9c9c9c;
    font-family: inherit;
    font-size: 16px;
    font-weight: 400;
    z-index: 0;
    border-radius: 10px;
  }
}

.filterTypeContainer {
  position: absolute;
  top: 40px;
  left: 0px;
  z-index: 30;
  width: 100%;
  height: 100%;
  &.date {
    top: 254px;
  }
}

.filterChips {
  position: relative;
  min-height: 33px;
  display: flex;
  .chips {
    max-width: 40vw;
    overflow-x: auto;
    max-height: 40px;
    display: flex;
    align-items: center;
  }
}

.filterIcon {
  margin-right: 8px;
}

.searchIcon {
  padding-top: 8px;
}

@media (max-width: 767px) {
  .filterChips {
    position: relative;
  }
  .filterTypeContainer {
    left: 0;
  }
  .searchField {
    width: 100%;
    left: 0;
    transform: none;
  }
  .search {
    margin: 5px 15px;
    input {
      width: 50px;
    }
  }
  .searchTags {
    justify-content: initial !important;
    > div {
      margin: 0 15px;
      &:last-of-type {
        &:after {
          content: '';
          padding: 8px;
        }
      }
    }
  }
}
