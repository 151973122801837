.colorPicker {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 35px;
  > div > span > div > span {
    box-shadow: 0 3px 4px 0 #ccc !important;
    border-radius: 100%;
  }
  .removeColor {
    position: relative;
    text-align: center;
    margin-top: 25px;
    z-index: 101;
    cursor: pointer;
    font-weight: bold;
    width: 100%;
  }
  .moreColors {
    margin-top: 30px;
    @media (max-width: 568px) {
      display: none;
    }
  }
  @media (max-width: 568px) {
    padding: 12px 0px;
    padding-bottom: initial;
  }
}
