.listContainer {
  display: flex;
  flex-direction: column;
  .itemContainer {
    display: flex;
    justify-content: space-between;
    padding-left: 13px;
    .itemDescription {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
    .itemActions {
      display: flex;
      align-items: center;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
@media (max-width: 783px) {
  .listContainer {
    .editOrder > div {
      margin-top: 15px;
    }
    .header {
      margin-bottom: 25px;
    }
  }
}
