$arrow-height: 6px;
$arrow-flat-edge: $arrow-height solid black;
$arrow-border: $arrow-height solid transparent;

@mixin arrow() {
  content: '';
  display: block;
  width: 0;
  height: 0;
  position: absolute;
}

.container {
  position: relative;
  &:hover {
    .tooltip {
      display: inherit;
    }
  }
}
.tooltip {
  display: none;
  position: absolute;
  background: #32353d;
  box-shadow: 0 8px 14px 0px rgba(216, 221, 230, 0.5);
  border-radius: 2px;
  transition: none;
  z-index: 1;
  padding: 15px;
  width: 250px;
}
.top {
  bottom: 100%;
  height: auto;
  transform: translate(-47%, -5px);
  &:before {
    @include arrow();
    border-top: $arrow-flat-edge;
    border-left: $arrow-border;
    border-right: $arrow-border;
    right: calc(50% - #{$arrow-height});
    bottom: (-$arrow-height);
  }
}
.bottom {
  transform: translate(-47%, 5px);
  &:before {
    @include arrow();
    border-bottom: $arrow-flat-edge;
    border-left: $arrow-border;
    border-right: $arrow-border;
    top: (-$arrow-height);
    right: calc(50% - #{$arrow-height});
  }
}
.left {
  transform: translate(-105%, calc(-50% - #{$arrow-height}));
  &:before {
    @include arrow();
    border-left: $arrow-flat-edge;
    border-top: $arrow-border;
    border-bottom: $arrow-border;
    right: (-$arrow-height);
    bottom: calc(50% - #{$arrow-height});
  }
}
.right {
  transform: translate(10%, calc(-50% - #{$arrow-height}));
  &:before {
    @include arrow();
    border-right: $arrow-flat-edge;
    border-top: $arrow-border;
    border-bottom: $arrow-border;
    left: (-$arrow-height);
    bottom: calc(50% - #{$arrow-height});
  }
}
.message {
  color: #fff;
  font-size: 1.5rem;
  height: auto;
  width: auto;
}
.icon {
  > div {
    cursor: pointer !important;
  }
}
.pulse {
  animation: pulse-animation 2s infinite;
}

@keyframes pulse-animation {
  0%,
  100% {
    opacity: 1;
  }
  50% {
    opacity: 0;
  }
}
