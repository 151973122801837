.tabBody {
  position: relative;
  padding-top: 95px;
  max-width: 1028px;
  margin: auto;
  height: calc(100vh - 200px);
  .emptyMessage {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10% 0;
    h4 {
      letter-spacing: 0.5px;
      color: #3a474e;
    }
    h5 {
      letter-spacing: 0.5px;
      font-size: 16px;
      padding: 15px;
    }
  }
  .campaignCount {
    font-weight: 300;
    font-size: 16px;
  }
}
