.block {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
  }
}

.name {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    margin-bottom: 1rem;
  }
}

.numbers {
  display: flex;
  flex-direction: column;
  > div {
    display: flex;
    flex-direction: row;
    margin-right: 1rem;
    max-width: 600px;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 0.5rem;
    > span {
      font-size: 1.5rem;
    }
    > h6 {
      font-size: 2rem;
      line-height: 2rem;
    }
  }
}

.noteBlock {
  padding: 15px 0;
  .date {
    font-weight: bold;
  }
  .actions {
    display: flex;
    > span {
      text-decoration: underline;
      color: #ff5689;
      &:first-of-type {
        margin-right: 20px;
      }
    }
  }
}

.dates {
  display: flex;
  flex-flow: column;
}
