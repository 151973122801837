.modalContainer {
  .closeButton {
    position: absolute;
    top: 7px;
    right: 12px;
  }
}

.dialog {
  @media (max-width: 648px) {
    width: 100% !important;
  }
}
