.status {
  display: flex;
  padding-top: 15px;
  cursor: default;
  &.progress {
    flex-direction: column;
  }
  .details {
    display: flex;
    width: 100%;
    > p {
      font-weight: bold;
    }
  }
}

.amount {
  margin-left: auto;
  padding-right: 30px;
}

@media (max-width: 1070px) {
  .accountActivePlan {
    flex-direction: column !important;
    align-items: flex-start;
    padding-right: 30px;
    div:first-of-type {
      margin: 0 !important;
      width: 100%;
      justify-content: space-between;
      .amount {
        padding: 0;
      }
    }
    div:nth-of-type(2) {
      margin: 0 !important;
    }
  }
}
