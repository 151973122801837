.itemContainer {
  height: 440px;
  width: 305px;
  display: flex;
  cursor: pointer;
  .buttonContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    position: absolute;
    bottom: 70px;
    width: 100%;
    height: 50px;
    transition: bottom 0.35s;
    transition-timing-function: ease-out;
    background-color: rgba(239, 240, 240, 0.84);
    margin: 0;
    padding: 0;

    .viewButton,
    .addButton {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 45%;
      height: 30px;
      text-align: center;
      font-weight: 400;
      cursor: pointer;
      text-transform: uppercase !important;
      letter-spacing: 0.155rem;
      font-size: 1.3rem;
      font-weight: 600;
    }
    .viewButton {
      background-color: #ff04a0;
      color: white;
      &:hover {
        background-color: #ff04a0;
      }
    }
    .addButton {
      border: 2pt solid #ff04a0;
      color: #ff04a0;
      &:hover {
        background-color: #ff04a0;
        p {
          color: white;
        }
      }
    }
  }
  &:hover .buttonContainer {
    display: flex;
    position: absolute;
    bottom: 120px;
  }
}
.giftImageContainer {
  background-color: #eeefef;
  width: 305px;
  height: 320px;
  .giftImage {
    width: 100%;
    height: 100%;
    background-size: cover;
    background-repeat: no-repeat;
  }
}
.itemDetails {
  position: absolute;
  display: flex;
  flex-direction: column;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 100%;
  padding: 1rem;
  box-sizing: border-box;
  height: 120px;

  p {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }
  .priceContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100px;
    justify-content: center;
  }
}
@media (max-width: 1023px) {
  .itemContainer {
    margin: 12px;
    width: 245px;
    height: 340px;
    .itemView {
      width: 245px;
      height: 340px;
    }
  }
  .giftImageContainer {
    width: 245px;
    height: 237px;
  }
}
