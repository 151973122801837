// card sizing aspect-ratio mixin
@mixin aspect-ratio($width, $height) {
  position: relative;
  &:before {
    display: block;
    content: ' ';
    width: 100%;
    padding-top: calc($height / $width) * 100%;
  }
  > .panelContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}
.ribbon {
  padding: 35px;
  margin-left: 30px;
}
.ribbonInfo {
  display: flex;
  margin: 20px 0;
  margin-right: 35px;
  margin-left: 5px;
  h5 {
    font-weight: 500;
  }
  .more {
    display: flex;
    margin-left: auto;
    font-weight: 400;
    align-items: center;
    cursor: pointer;
    svg {
      margin-left: 8px !important;
      cursor: pointer;
    }
  }
}
.itemContainer {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  padding-bottom: 30px;
  align-items: center;
  z-index: 1000;
  overflow-y: hidden;
  .tile {
    flex: 0 0 auto;
    margin: 10px 10px 30px 10px;
    background: #fff;
    box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
    border-radius: 2px;
    z-index: 0;
    transition: 0.5s cubic-bezier(0.4, 0.4, 0, 1);
    cursor: pointer;
    background-size: cover;
    position: relative;
    width: 320px;
    height: 150px;
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
      font-size: 2.25rem;
      font-weight: 600;
      letter-spacing: 1px;
      z-index: 2;
      color: #fff;
      text-shadow: 0px 1px 2px #686868;
    }
    &:after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(81, 102, 116, 0.3);
      z-index: 1;
    }
  }
  .badSearch {
    background: transparent;
    box-shadow: none;
    margin: 50px auto;
    text-align: center;
    h4 {
      line-height: 50px;
      color: #333333;
      span {
        background: linear-gradient(115deg, #e687ff, #ff5689);
        color: #e687ff;
        background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
}
.portrait {
  width: 230.7px;
  height: 320px;
  background-size: cover;
  box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
}
.landscape {
  width: 320px;
  height: 230.7px;
  background-size: cover;
  box-shadow: 0 4px 5px 0 rgb(212, 212, 212);
}
