.avatarAction {
  position: relative;
  width: 100%;
  .avatar {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > div {
      box-shadow: 0 2px 14px 0 #eaeaea;
      border-radius: 100%;
    }
    .uploadActions {
      margin-top: 20px;
      display: flex;
      flex-direction: row;
      align-items: center;
      box-shadow: none;
    }
  }

  .profileActionHover {
    display: none;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: absolute;
    background-color: rgba(88, 88, 88, 0.81);
    top: 0;
    border-radius: 100%;
    cursor: pointer;
    p {
      color: #fff;
      position: relative;
      top: 4px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  &:hover {
    .profileActionHover.hover {
      display: flex;
    }
  }
}
