.walletContents {
  overflow-y: auto;
  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    margin: 0;
    line-height: 1.4;
  }

  h1 {
    font-size: 42px;
    color: #6f8394;
    text-align: left;
  }

  h2 {
    font-size: 28px;
    letter-spacing: -2px;
    color: #6f8394;
    text-align: left;
    line-height: 2.8;
  }

  h3 {
    margin: 0;
    padding: 0 0 24px 20px;
    color: #6f8394;
    font-size: 22px;
    line-height: 32px;
    font-weight: 400;
  }

  h4 {
    font-size: 16px;
    color: #6f8394;
    letter-spacing: 1px;
    text-align: left;
    line-height: 2;
  }

  h5 {
    font-size: 11px;
    font-weight: 700;
    color: #f9fafb;
    letter-spacing: 1px;
    text-align: left;
    text-transform: uppercase;
  }

  h5 > span {
    margin-left: 87px;
  }

  h5.total {
    margin-top: 20px;
  }

  h6 {
    font-family: 'Open Sans';
    font-size: 16px;
    font-weight: 400;
    color: #f4f5f9;
    letter-spacing: 0;
    text-align: left;
    text-transform: uppercase;
    line-height: 1.8;
  }

  h6 > span {
    margin-left: 64px;
  }
}
.flex {
  display: flex;
  flex-wrap: wrap;
}
.iconInput {
  position: relative;
  svg {
    position: absolute;
    top: 30px;
  }
  input {
    width: calc(100% - 28px);
    margin-left: 28px;
  }
}
.centerBlock {
  text-align: center;
  margin-top: 25px;
}
.buttonBlock {
  margin-top: 30px;
  display: flex;
  button {
    width: 50%;
    margin-right: 15px;
    &:nth-of-type {
      margin-right: 0;
    }
  }
  @media (max-width: 568px) {
    justify-content: center;
    position: fixed;
    bottom: 0;
    left: 0;
    background: #fff;
    width: 100%;
    height: 55px;
    align-items: center;
    z-index: 1000;
    box-shadow: 0 2px 28px -2px #dedede;
  }
}
.payment {
  z-index: 0;
  width: 100%;
  position: relative;
}
.colorPicker {
  position: relative;
  left: 62px;
}
.flexFields {
  display: flex;
  > p:first-of-type {
    margin-right: 5px;
  }
}
.cardForm {
  width: 100%;
  position: relative;
  padding: 15px 35px;
  overflow: hidden;
  @media (max-width: 568px) {
    padding: 0;
  }
  p.field {
    height: 40px;
    line-height: 34px;
    padding: 2px 10px;
    margin-bottom: 8px;
    background-color: #f4f5f9;
    border: 1px solid #d2d4de;
    display: inline-block;
    -webkit-border-radius: 6px;
    -moz-border-radius: 6px;
    border-radius: 6px;
    width: 100%;
    &.cvv {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .layout {
    p.field {
      width: 50%;
      &.space {
        width: calc(50% - 10px);
      }
    }
  }
}

// input[type='text'] {
//   height: 28px;
//   position: relative;
//   padding: 0 10px 0 6px;
//   background-color: transparent;
//   border: none;
//   color: #000;
//   font-family: 'PT Mono';
//   font-size: 16px;
//   font-weight: 400;
//   z-index: 0;
//   &:focus {
//     outline: none;
//   }
// }

::-webkit-input-placeholder,
:-moz-placeholder,
::-moz-placeholder,
:-ms-input-placeholder {
  color: #dddfe6;
}

#iCardfront,
#iCardback,
#iCalendar,
#iStreet,
#iPayment,
#iUser,
.iconStyle {
  position: relative;
  top: 6px;
  z-index: 1;
}

#cardNumber {
  width: calc(100% - 28px);
}
#cardExpiry {
  width: calc(100% - 28px);
}
#cardName {
  width: calc(100% - 28px);
}
#labelCardName {
  font-size: 12px;
  text-transform: uppercase !important;
  padding-top: 3px;
}
#streetAddress,
#city,
#state,
#postalCode,
#country {
  width: calc(100% - 30px);
}
.space {
  margin-right: 10px;
}

.buttonCta {
  width: 100%;
  height: 65px;
  position: absolute;
  float: right;
  right: 0px;
  bottom: -68px;
  padding: 10px 20px;
  background-color: #f1404b;
  border: 1px solid #f1404b;
  // font-family: 'Quicksand', sans-serif;
  font-weight: 700;
  font-size: 24px;
  color: #f4f5f9;
  z-index: -1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  &:hover {
    background: rgba(193, 14, 26, 1);
    border: 1px solid rgba(193, 14, 26, 1);
  }
  span {
    display: inline-block;
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    &:after {
      content: '→';
      color: #f4f5f9;
      position: absolute;
      opacity: 0;
      top: 0;
      right: -40px;
    }
  }
  &:hover span {
    padding-right: 45px;
    &:after {
      opacity: 1;
      right: 0;
    }
  }
}

/*--------------------
Credit Card Background
---------------------*/

.billingAddressTitle {
  box-sizing: border-box;
  color: rgba(158, 158, 158, 0.54);
  font-size: 16px;
  font-weight: 500;
  line-height: 48px;
  width: 100%;
}
