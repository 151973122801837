@import '../../chrome/styles/variables';
svg {
  pointer-events: none;
  cursor: pointer;
}
.type {
  display: inline-block;
  background-image: linear-gradient(115deg, #ff5689, #e687ff);
  box-shadow: 0 1px 3px #d1d1d1, 0 1px 2px #d1d1d1;
  padding: 0px 10px;
  position: relative;
  left: 2px;
  border-radius: 3px;
  font-weight: 500;
  font-size: 12px;
  color: #fff;
  span {
    font-weight: bold;
    font-size: 13px;
    color: #fff;
    margin-left: 7px;
  }
}
.flex {
  display: flex;
  width: 100%;
  &.fullHeight {
    height: 100vh;
    overflow-y: hidden;
  }
  &.contactUpload {
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-wrap: wrap;
    height: 80vh;
    > div {
      text-align: center;
      padding: 5px;
      background-color: #fff;
      border-radius: 5px;
      min-height: 60px;
      border: 2px dashed #f961a3;
      width: 90%;
      height: 65vh;
    }
  }
}
.flexButton {
  display: flex;
  padding: 10px 15px;
  .drawerAction {
    margin-left: auto !important;
  }
  &.contactActions {
    background: #fff;
    box-shadow: 0 2px 8px #e3e3e3;
    .attachButton {
      margin-left: auto !important;
      margin-right: 15px;
      cursor: pointer;
    }
  }
}
.addresses {
  span {
    display: initial !important;
    margin: initial !important;
  }
}
.addContactActions {
  display: flex;
  margin-left: auto;
  > div {
    margin-right: 15px;
  }
}
.actionItems {
  > span {
    margin-right: 22px;
    cursor: pointer !important;
    > div {
      cursor: pointer !important;
    }
  }
}
.tableSpacer {
  width: 100%;
  height: 20px;
}
.textCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3rem;
  color: #b4b4b4;
  text-transform: uppercase !important;
}
.addItemActions {
  text-align: center;
  p {
    margin-bottom: 35px;
  }
  button {
    display: inline-block;
    &:first-of-type {
      margin-right: 25px;
    }
  }
}
.addressCheckBox {
  margin-bottom: 0;
  margin-left: 13px;
}
.orderWrapper {
  .addressDisplay {
    tr {
      box-shadow: 0 1px 8px 1px #dedede;
      &.tableSpacer {
        box-shadow: none;
      }
    }
  }
  .badge {
    position: relative;
    bottom: 5px;
    right: 2px;
    width: 20px;
    height: 20px;
    background: $pink;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    border-radius: 100%;
    box-shadow: $shadows;
  }
  .addressBook,
  .overFlow {
    height: 100%;
    overflow-y: auto;
    padding-top: 25px;
    margin-top: 10px;
    &.addressPadding {
      padding: 0 35px 80px 35px;
    }
    .addressSearch {
      margin: 35px auto;
      padding-bottom: 0px;
      top: 0;
    }
    .addressSearchContainer {
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .attachedAddressContainer {
    width: 100%;
    .addressShadows {
      box-shadow: 0 2px 15px -1px #c0c0c0;
    }
  }
  .attachedAddressBar {
    width: 100%;
    display: flex;
    padding: 15px 25px;
    color: #4b4b4b;
    fill: #4b4b4b;
    align-items: center;
    svg {
      margin-right: 5px;
    }
    a {
      margin-right: 20px;
    }
  }
  .detailDrawer {
    top: 0;
    width: calc(100vw - 65px);
    height: 100vh;
    min-height: 150px;
    z-index: 120;
    overflow: hidden;
    .drawerHeader {
      display: flex;
      padding-top: 15px;
      h6 {
        margin-left: 10px;
      }
    }
    .drawerAction {
      border-radius: 100px;
      padding: 4px 15px;
      background-color: #5b676d;
      span {
        cursor: pointer;
        font-weight: 600;
        color: $white;
        font-size: 11px;
      }
      svg {
        margin-right: 5px !important;
        path {
          fill: $white;
        }
      }
    }
  }
}

.issueList {
  display: flex;
  flex-direction: column;
  .issue {
    color: white;
    padding: 5px;
    &:hover {
      background: gray;
    }
  }
}

.sendButton {
  position: fixed;
  bottom: 65px;
  width: 100%;
  background: #ffffffff;
  box-shadow: 0px -11px 11px 0px rgba(215, 215, 215, 1);
  border-bottom: rgb(214, 214, 214) 1px solid;
  z-index: 101;
  padding: 8px 20%;
}

@media (max-width: 730px) {
  .attachedAddressBar {
    flex-wrap: wrap;
    justify-content: center;
    .addContactActions {
      flex-wrap: wrap;
      margin-left: initial;
      margin-top: 20px;
      flex: 1 1 auto;
      > div {
        flex: 1 1 auto;
        margin-bottom: 18px;
      }
    }
  }
  .hideMobile {
    display: none;
  }
}
@media (max-width: 568px) {
  .orderWrapper {
    .detailDrawer {
      width: 100%;
    }
  }
}
@media (max-width: 450px) {
  .addressSearchContainer {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
  }
}
