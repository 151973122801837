.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-left: 17px;
}
.actionButtons {
  display: flex;
  min-width: 240px;
  justify-content: space-between;
  align-items: center;
}
.counts {
  span:first-of-type {
    font-weight: bold;
  }
  span:not(:first-of-type) {
    margin-left: 15px;
    cursor: pointer;
  }
}

@media (max-width: 470px) {
  .counts {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    span:first-of-type {
      display: none;
    }
    span:not(:first-of-type) {
      margin-left: 0;
    }
  }
  .actionButtons {
    width: auto;
    .createButton {
      margin: 0;
    }
  }
}
