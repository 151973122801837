$color-white: #fff;
$icon-size-default: 48px;
$color-highlight: #4b9ce3;

@mixin fill-parent($offset) {
  position: absolute;
  top: $offset;
  right: $offset;
  bottom: $offset;
  left: $offset;
}
.blinkerContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.blinker {
  flex: none;
  width: $icon-size-default;
  height: $icon-size-default;
  position: relative;
  pointer-events: none;
  .circle {
    &:nth-child(1) {
      @include fill-parent(8px);
      background: $color-highlight;
      border-radius: 100%;
      border: 4px solid $color-white;
      z-index: 1;
      box-shadow: 0 0 6px 2px rgba(0, 0, 0, 0.1);
    }
    &:nth-child(2) {
      @include fill-parent(10px);
      background: $color-highlight;
      border-radius: 100%;
      animation: pulse-out 1200ms infinite;
    }
  }
}
.blinkerMessage {
  position: relative;
  display: inline-block;
  .message {
    width: 120px;
    background-color: black;
    color: #fff;
    text-align: center;
    padding: 5px 0;
    border-radius: 6px;
    position: absolute;
    z-index: 1;
    &::after {
      content: ' ';
      position: absolute;
      top: 100%;
      left: 50%;
      margin-left: -5px;
      border-width: 5px;
      border-style: solid;
      border-color: black transparent transparent transparent;
    }
  }
}
@keyframes pulse-out {
  0% {
  }
  100% {
    transform: scale(2);
    opacity: 0;
  }
}
