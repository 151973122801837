@import '../../../chrome/styles/variables';

// Base Colors
$shade-10: $purple !default;
$shade-1: #dedede !default;
$shade-0: #fff !default;
$active: $green !default;

.range-slider {
  margin: 60px 0 0 0%;
}

// Range Slider
$range-width: 100% !default;

$range-handle-color: $shade-10 !default;
$range-handle-color-hover: $active !default;
$range-handle-size: 15px !default;

$range-track-color: $shade-1 !default;
$range-track-height: 10px !default;

$range-label-color: $shade-10 !default;
$range-label-width: 60px !default;

// subject to change with the sizing algorithm
.imageTrigger {
  position: relative;
  z-index: 2000;
}
.imageEditor {
  position: absolute;
  top: 0;
  left: 0;
  touch-action: none;
}
.progressLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.activeImage {
  touch-action: manipulation;
  > div > canvas {
    touch-action: manipulation;
    border: 1px solid #ff5689;
    border-radius: 2px;
    box-shadow: 0 1px 15px 0 rgba(239, 146, 251, 0.3);
    cursor: all-scroll !important;
  }
}
.inactiveImage {
  height: 0px;
  overflow: visible;
  pointer-events: none;
  background: none !important;
}
canvas {
  width: 100%;
  touch-action: manipulation;
  cursor: default;
}
.range-slider {
  width: $range-width;
}
.imageScale {
  background: #fff;
  padding: 0 35px;
  position: relative;
  min-width: 150px;
  height: 20px;
  z-index: 105;
  display: inline-block;
  /* border-radius: 3px; */
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0 1px 3px rgba(202, 202, 202, 0.47),
    0 1px 2px rgba(202, 202, 202, 0.46);
  &.desktop {
    position: absolute;
    bottom: 10px;
  }
  input[type='range'] {
    min-width: 150px;
  }
}
.imageScale input[type='range'] {
  -webkit-appearance: none;
  height: 4px;
  background: #e5e5e5;
  outline: none;
  padding: 0;
  margin: 0;
  left: 50%;
  position: relative;
  bottom: 3px;
  -webkit-transform: translateX(-50%);
  -ms-transform: translateX(-50%);
  transform: translateX(-50%);
  @media (max-width: 586px) {
    width: 50%;
  }
  // Range Handle
  &::-webkit-slider-thumb {
    appearance: none;
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 2px;
    background: linear-gradient(
      135deg,
      rgba(248, 78, 129, 0.89),
      rgba(204, 120, 225, 0.89)
    );
    cursor: pointer;
    transition: background 0.15s ease-in-out;
  }

  &::-moz-range-thumb {
    width: $range-handle-size;
    height: $range-handle-size;
    border-radius: 2px;
    background: linear-gradient(
      135deg,
      rgba(248, 78, 129, 0.89),
      rgba(204, 120, 225, 0.89)
    );
    cursor: pointer;
    transition: background 0.15s ease-in-out;
  }
}

// Range Label
.range-slider__value {
  display: inline-block;
  position: relative;
  width: $range-label-width;
  color: $shade-0;
  line-height: 20px;
  text-align: center;
  border-radius: 3px;
  background: $range-label-color;
  padding: 5px 10px;
  margin-left: 8px;

  &:after {
    position: absolute;
    top: 8px;
    left: -7px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-right: 7px solid $range-label-color;
    border-bottom: 7px solid transparent;
    content: '';
  }
}

// Firefox Overrides
::-moz-range-track {
  background: $range-track-color;
  border: 0;
}

input::-moz-focus-inner,
input::-moz-focus-outer {
  border: 0;
}
