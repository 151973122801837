$svgFill: #fd5a92 !important;
$svgHoverFill: #fff !important;

.options {
  display: flex;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 122px;
  height: 32px;
  justify-content: center;
  align-items: center;
  z-index: 1002;
  margin: auto;
  background-image: linear-gradient(115deg, #ff5689, #e687ff);
  box-shadow: 0 1px 15px 0 rgba(239, 146, 251, 0.3);
  border-radius: 90px;
}
span {
  position: relative;
  // cursor: pointer;
  transition: all 0.25s ease-out;
}
.optionOne {
  width: 56px;
  text-align: center;
  background: #fff;
  padding: 4px;
  border-radius: 90px 0 0 90px;
  position: relative;
  right: 0.5px;
  cursor: pointer;
  svg path {
    fill: $svgFill;
  }
  &:hover {
    background: none;
    svg path {
      fill: $svgHoverFill;
    }
  }
}
.optionTwo {
  width: 60.124px;
  text-align: center;
  background: #fff;
  padding: 4px;
  border-radius: 0 90px 90px 0;
  position: relative;
  left: 0.5px;
  cursor: pointer;
  svg path {
    stroke: $svgFill;
  }
  &:hover {
    background: none;
    svg path {
      stroke: $svgHoverFill;
    }
  }
}
