.textControlBar {
  overflow: hidden;
  display: flex;
  flex: 1 1 auto;
}
.controlsWrapper {
  width: 100%;
  overflow: auto;
  height: calc(100vh - 100px);
}
.buttonFlexBlock {
  display: flex;
  justify-content: center;
  position: fixed;
  bottom: 50px;
  left: 65px;
  width: 315px;
  padding-bottom: 15px;
  padding-top: 15px;
}
.control {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  margin-bottom: 25px;
  h6 {
    font-size: 1em;
    color: #6f8394;
    display: inline-block;
    margin-bottom: 25px;
    background: #f1f1f1;
    font-weight: 500;
    padding-left: 10px;
    letter-spacing: 0.85px;
  }
  .sidenote {
    color: #6f8394;
    background: #f1f1f1;
    padding-left: 10px;
    margin-bottom: 25px;
    font-size: 0.75rem;
  }
}

@media (max-width: 568px) {
  .control {
    display: none;
    margin-bottom: 0;
    padding: 15px;
    h6 {
      display: none;
    }
    &.activeItem {
      display: flex;
    }
  }
  .buttonFlexBlock {
    left: 0;
    width: 100%;
  }
}
