@import '../../app/styles/variables';

.tabContent {
  position: relative;
  @media (max-width: 568px) {
    height: initial;
    width: 100vw;
    left: 0;
  }
}
.textComponent {
  position: absolute;
  display: flex;
  overflow: hidden;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 101;
  border: 1px dashed rgba(255, 173, 204, 0.58);
}
.callToAction {
  position: absolute;
  bottom: 50px;
  right: 50px;
}
// Media Queries
@media (max-width: 568px) {
  .tabContent {
    > div > section {
      height: calc(
        100vh - 178px
      ); //sidebar + tabBar {height/width}// 178px with mobile safari bar
    }
    nav {
      background: #fff;
      justify-content: center;
    }
  }
  .callToAction {
    width: 100%;
    display: flex;
    justify-content: center;
    padding: 0 15px;
    right: 0 !important;
    position: absolute;
    top: 20px;
    bottom: initial;
    a {
      margin-right: 0;
    }
  }
}

// Hide action buttons if mobile keyboard is open
@media (max-height: 400px) {
  .callToAction {
    display: none;
  }
}
