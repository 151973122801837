$breakpoint-alpha: 784px;
.tableSpacer {
  height: 6px;
}

.contactActions {
  display: flex;
  .checkbox {
    width: auto !important;
  }
  > span {
    padding-left: 20px;
  }
}
.actionCell {
  width: 20px;
  @media (max-width: $breakpoint-alpha) {
    width: 100%;
  }
}
.iconMenu {
  @media (max-width: $breakpoint-alpha) {
    display: none;
  }
}
.actionButtons {
  display: flex;
  @media (min-width: $breakpoint-alpha) {
    display: none;
  }
  .actionButton {
    width: 100px;
    margin-right: 15px;
  }
}

td {
  padding: 1rem !important;
  position: relative;
}

.Address {
  height: auto;
}
