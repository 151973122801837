@import '../../../chrome/styles/variables';

.container {
  display: flex;
  width: 100%;
  padding-top: 25px;
  align-items: center;
  flex-direction: column;
  h4 {
    letter-spacing: 0.5px;
    color: #3a474e;
    font-size: 17px;
    text-align: center;
  }
  h6 {
    letter-spacing: 0.5px;
    font-weight: 400;
    font-size: 16px;
    padding: 15px 0;
  }
  .selectorBlock {
    display: inline-flex;
    padding: 30px 0 20px 0;
  }
  .allGroups {
    width: 30%;
    min-width: 350px;
    margin-right: 20px;
  }
  .contactGroups {
    width: 30%;
    min-width: 350px;
    .selectedGroups {
      height: 368px;
      box-shadow: $card-shadow;
      border-radius: $card-radius;
    }
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
}

@media (max-width: 800px) {
  .container {
    padding-top: 0;
    align-items: flex-start;
    .selectorBlock {
      flex-direction: column;
      padding-top: 5px;
      width: 100%;
      .allGroups {
        width: 90%;
        min-width: 98%;
        margin-right: 0;
        > div {
          > div:nth-of-type(2) {
            height: 170px !important;
          }
        }
      }
      .contactGroups {
        width: 90%;
        min-width: 98%;
        .selectedGroups {
          height: 200px;
        }
      }
    }
  }
  .actions {
    width: 100%;
    justify-content: center !important;
  }
}
