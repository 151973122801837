.tableSpacer {
  height: 3px;
}
.body {
  color: #6f8394;
  .close {
    position: absolute;
    top: 12px;
    right: 12px;
    z-index: 200;
  }
  .info {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin: auto;
    align-items: center;
  }
  .actions {
    display: flex;
    justify-content: flex-end;
  }
  .groupDetails {
    display: flex;
  }
  .tableContainer {
    padding: 15px;
    max-height: 500px;
    overflow-y: auto;
  }
}

.contactActions {
  display: flex;
  .checkbox {
    width: auto !important;
  }
}

td {
  padding: 12px !important;
}

.noMembers {
  padding: 20px;
}
