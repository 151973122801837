.outerContainer {
  display: flex;
  flex-direction: column;
}

.container {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  padding: 0 2rem;
  .column {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

@media (max-width: 890px) {
  .container {
    flex-wrap: wrap;
    padding: 0 0 1rem 0;
    .column {
      flex-flow: row wrap;
      &.order2 {
        order: 2;
        width: 100%;
        flex-direction: row;
        > div {
          width: 100%;
        }
      }
    }
  }
}

.tableSpacer {
  height: 2px;
}

.sendButton {
  width: 80%;
  margin-left: 10%;
  background: #ffffffff;
  box-shadow: 0px -11px 11px 0px rgba(215, 215, 215, 1);
  border-bottom: rgb(214, 214, 214) 1px solid;
  z-index: 116;
}
