.explainMessage {
  font-size: 1.8rem;
  color: #6f8394;
}

.links {
  a {
    width: 100%;
    text-align: center;
    color: #ff5689;
    text-decoration: none;
    letter-spacing: 0.5px;
    font-weight: 700;
    margin: 0.5rem;
  }
}

.userForm {
  width: 100%;
  padding-right: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.saveUser {
  margin-top: 30px;
}

.successMessage {
  text-align: center;
  margin-top: 1rem;
  font-size: 1.8rem;
  color: #6f8394;

  span {
    display: block;
    width: 100%;
    text-align: center;
    font-weight: 600;
    margin-bottom: 0.5rem;
  }
}

.errorMessage {
  text-align: center;
  margin-top: 30px;
  color: red;
}

.oneField {
  width: 100%;
}

.forgotButton {
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #e687ff;
  }
}
