.container {
  overflow: auto;
  padding-bottom: 65px;
  .body {
    padding: 0 25px;
  }
}

.store-enter {
  opacity: 0.01;
  width: 0px;
  z-index: 1000;
}

.store-enter.store-enter-active {
  opacity: 1;
  width: 100px;
  transition: 300ms;
  z-index: 1000;
}

.store-exit {
  opacity: 1;
  width: 100px;
  z-index: 1000;
}

.store-exit.store-exit-active {
  opacity: 0.01;
  width: 0px;
  transition: 300ms;
  z-index: 1000;
}
