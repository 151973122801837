.editorPanel {
  height: 100%;
  width: 65px;
  display: flex;
  flex-direction: column;
  transition: transform 0.5s cubic-bezier(0.4, 0.4, 0, 1);
  > {
    nav {
      width: 65px;
      bottom: 0;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      box-shadow: 1px 0 rgba(99, 114, 130, 0.16);
      transition: transform 0.5s cubic-bezier(0.4, 0.4, 0, 1);
      z-index: 10000;
      > {
        .menuBottom {
          display: flex;
          flex-direction: column;
          margin-top: auto;
          align-content: flex-end;
        }
        .editorPanelLogo {
          display: flex;
          width: 65px;
          height: 65px;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          &:hover {
            background: rgba(0, 0, 0, 0.08);
          }
          > {
            .logo {
              display: flex;
              flex-direction: column;
              align-items: center;
              p {
                font-size: 10px;
                font-weight: 500;
              }
            }
            .backArrow {
              position: absolute;
              top: 28px;
              left: 7px;
            }
          }
        }
      }
      .menu {
        width: 100%;
        height: 74px;
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &.historyControl {
          margin-top: auto;
          height: 178px;
        }
        &.conditional {
          display: none;
          &.showItem {
            display: flex;
          }
        }
        &:hover {
          background: rgba(0, 0, 0, 0.04);
          transition: none;
          opacity: 1;
          &:active {
            background: rgba(0, 0, 0, 0.06);
            transition: 0.2s cubic-bezier(0.4, 0.4, 0, 1);
          }
        }

        > {
          img {
            margin-bottom: 10px;
          }
          p {
            font-size: 10px;
            font-weight: 500;
          }
        }
        &.small {
          height: 20px;
          opacity: 0.5;
        }
      }
      .itemBottom {
        position: absolute;
        bottom: 0;
        width: 64px;
        @media (max-width: 568px) {
          position: relative;
          bottom: initial;
          display: flex;
        }
      }
    }
    div {
      // position: absolute;
      // left: 65px;
      // width: 300px;
      // top: 0;
      // bottom: 0;
      // background-color: #fff;
      // z-index: 8000;
      // box-shadow: 1px 0 rgba(99, 114, 130, 0.16);
      // transition: transform 0.5s cubic-bezier(0.4, 0.4, 0, 1);
      // transform: translateX(-370px);
      > button {
        position: absolute;
        left: 220px;
        margin-top: 33px;
        top: 50%;
        transform: translateY(-50%);
        background: #fff;
        width: 40px;
        height: 40px;
        border-radius: 30px;
        box-shadow: 0 1px 4px rgba(0, 0, 0, 0.1);
        z-index: 8888;
      }
    }
  }
  &.open > div {
    transform: translateX(0px);
  }
}

//Editor Panel Drawers
.toolbarPanel {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  flex-direction: column;
  flex-wrap: nowrap;
  .panelNav {
    width: 100%;
    height: 65px;
    border-bottom: 1px solid #f1f1f1;
    > li {
      width: 50%;
      height: 100%;
      justify-content: center;
      align-items: center;
      align-content: center;
      font-size: 12px;
      text-transform: capitalize;
      cursor: pointer;
      &.selected,
      &:hover {
        color: #ff5689;
      }
    }
  }
}

.blockButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

@media (max-width: 568px) {
  .editorPanel {
    -ms-flex-direction: row;
    flex-direction: row;
    top: initial;
    width: 100vw;
    position: unset;
    z-index: 500;
    > nav {
      position: unset;
      width: 100vw;

      flex-direction: row;
      .itemContainer {
        display: flex;
        flex-wrap: nowrap;
        justify-content: space-evenly;
        width: 100vw;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
      }
      .menu {
        height: auto;
        width: 60px;
        flex: 0 0 auto;
      }
      .menuBottom {
        display: none;
      }
    }
  }
}

.confirmWrapper {
  width: 100vw !important;
  z-index: 90000 !important;
  transform: translateX(0px) !important;
}
