.container {
  margin-right: auto;
  padding-left: 25px;
  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }
}

@media (max-width: 815px) {
  .container {
    margin: 0;
    .desktop {
      display: none !important;
    }
    .mobile {
      display: initial !important;
    }
  }
}
