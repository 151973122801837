.catalogSearchBar {
  width: 100%;
}
.catalogSearchField {
  width: 750px;
  margin: 0 auto;
  @media (max-width: 815px) {
    width: 100%;
    border-bottom: 1px solid #f2f2f2;
  }
}
.catalogSearchTags {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  justify-content: space-evenly;
  height: 65px;
  align-items: center;
  position: relative;
  top: 10px;
  > div {
    padding-bottom: 0;
  }
  @media (max-width: 400px) {
    top: 65px;
  }
}
