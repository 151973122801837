.actionButton {
  width: 150px;
  cursor: pointer;
}

.linesContainer {
  display: flex;
  flex-wrap: wrap;
  overflow-y: scroll;
  margin-bottom: 15px;

  .tileContainer {
    position: relative;
    margin: 0 15px 20px 0;
    min-height: 225px;
    cursor: pointer;

    &.horizontal {
      display: flex;
      align-items: center;
    }

    .card {
      background-size: cover;
      height: 221px;
      width: 158px;
      box-shadow: 0 4px 5px 0 #d4d4d4;

      &.horizontal {
        height: 158px;
        width: 221px;
      }
    }

    .gift {
      position: absolute;
      bottom: -2px;
      right: -5px;
      height: 75px;
      width: 75px;
      border-radius: 8px;
      background-size: cover;
    }
  }
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.9);
  display: flex;
  z-index: 2;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  > div {
    background: rgba(255, 255, 255, 1);
    padding: 15px;
    border-radius: 10px;
  }

  h4 {
    letter-spacing: 0.5px;
    font-size: 18px;
    color: #3a474e;
    text-align: center;
  }
}

.modalFooter {
  display: flex;
  justify-content: space-between;

  .footerButtons {
    display: flex;
  }
}

.nameInput {
  color: black;
}

.nameInputLabel {
  font-weight: 800;
}

.disabledNameInput {
  border-bottom: 1px solid black;
}

@media (max-width: 764px) {
  .body {
    height: initial !important;
    max-height: initial !important;
    flex-grow: 1;
    padding: 0;
  }
  .content {
    height: calc(100vh - 70px);
    transform: initial !important;
    width: 100% !important;
    max-width: 100% !important;

    > div {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;

      .linesContainer {
        padding: 10px;
      }

      .modalFooter {
        position: absolute;

        left: 0;
        z-index: 2;
        background: white;
        height: auto;
        padding: 15px;
        width: 100%;

        .actionButton {
          position: relative;
          margin: initial;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .tileContainer {
    min-height: 148px !important;
    margin: 0 13px 20px 0 !important;

    .card {
      height: 138px !important;
      width: 99px !important;

      &:horizontal {
        height: 99px !important;
        width: 138px !important;
      }
    }
  }
}
