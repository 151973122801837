@import '../styles/Chrome.module.scss';

.tableContainer {
  overflow-x: auto;
  max-width: calc(100vw - 200px);
  .table {
    overflow-y: auto;
    padding-bottom: 15px;
    text-align: left;
    .header {
      text-align: left;
    }
    .cell {
      border-right: 1px solid #ebebeb;
      border-bottom: 1px solid #ebebeb;
      margin: 0 10px;
      padding: 5px 6px !important;
      input {
        font-family: $primaryFont;
      }
      .cellBody {
        position: relative;
        .cellValue {
          white-space: nowrap;
          display: inline-block;
          width: 100%;
          height: 12px;
        }
        &:hover {
          .hoverMessage {
            display: initial;
            position: absolute;
            bottom: 0;
            height: auto;
            width: auto;
            white-space: nowrap;
            font-size: 1.5rem;
            background: #fff;
            box-shadow: 0 2px 9px 0 rgba(0, 0, 0, 0.3);
            border-radius: 2px;
            transition: none;
            z-index: 1;
            padding: 15px;
          }
        }
        .hoverMessage {
          display: none;
        }
      }
    }
  }
}
