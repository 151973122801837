// TODO: refactor the following files to uses scss modules
// GiftDetail
// GiftNoCard
// MultiLineRibbon
// OrderDetail
// ReturnAddress
// SingleCard

@import 'variables';
.centered {
  padding: 15px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  h5 {
    width: 100%;
    text-align: center;
    font-size: 2.5rem;
    color: #b4b4b4;
    text-transform: uppercase !important;
    margin-bottom: 20px;
  }
  a {
    margin-top: 25px;
  }
}
.cardShadow {
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.15);
}
.buttonFlex {
  width: 100%;
  display: flex;
  justify-content: center;
}
.pink {
  color: $pink !important;
}
.loader {
  z-index: 1000 !important;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  svg {
    top: 0;
    left: 0;
  }
}
.flex {
  display: flex;
  width: 100%;
}
.flexButton {
  display: flex;
  justify-content: center;
  margin-top: 15px;
}
.giftActionButton {
  width: 80%;
  justify-content: center;
  margin-top: 40px;
}
.orderView {
  .previewModal {
    overflow: hidden;
  }
}
.removeItem {
  text-align: center;
  cursor: pointer;
  svg {
    display: inline;
    position: relative;
    bottom: 3px;
    right: 4px;
    path {
      fill: #f56d9b;
    }
  }
  p {
    font-size: 16px;
    display: inline;
  }
}
.previewSection {
  position: relative;
  display: flex;
  max-width: 1050px;
  margin: 0 auto;
  padding: 25px 50px;
  &.overviewCard {
    background: $white;
    border-radius: 28px;
    box-shadow: $card-shadow;
    @media (min-width: 1600px) {
      justify-content: center;
    }
    section {
      min-height: 210px;
      text-align: center;
    }
  }
  &.cardPreview {
    img {
      max-width: 328px;
      max-height: 520px;
      width: auto;
      height: auto;
      box-shadow: 0 3px 6px rgba(0, 0, 0, 0.12), 0 3px 6px rgba(0, 0, 0, 0.24);
      @media (max-width: 991px) {
        max-width: 250px;
      }
    }
  }
  &.giftPreview {
    padding: 0;
    img {
      width: calc(100% - 10px);
      margin: 10px 0 0 10px;
      box-shadow: 0 5px 15px 1px #909da5;
    }
  }
  .close {
    position: absolute;
    top: 5px;
    right: 5px;
  }
}
.cardInfo {
  width: 50%;
  margin: 40px 50px 50px;
  align-items: center;
  position: relative;
  .priceLabel {
    margin-top: 25px;
    margin-bottom: 4px;
    text-transform: uppercase !important;
    letter-spacing: 0.155rem;
    font-size: 1.3rem;
    font-weight: 600;
    color: #4b4b4b;
  }
  .pricePoint {
    font-size: 22px;
    font-weight: 300;
    color: #686b72;
  }
  h3 {
    position: relative;
    margin-bottom: 25px;
    font-size: 2.6rem;
    font-weight: 400;
    line-height: 3.8rem;
    letter-spacing: 0.1rem;
    color: #7e858d;
  }
  h4 {
    color: #686b72;
  }
}

// Card Actions
.cardActions {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  margin-top: 50px;
  &.gift {
    display: block;
    margin-top: 25px;
    margin-bottom: 25px;
    text-align: center;
    h6,
    p {
      text-align: left;
    }
    button {
      // width: 250px;
      padding: 18px 0;
      margin-top: 30px;
      line-height: 0;
    }
  }
  a {
    margin-left: 20px;
  }
  button {
    display: inline;
    margin-right: 20px;
  }
}

// Preview Modal
.previewModal {
  position: fixed;
  background: #fff;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
  z-index: 102;
  &.modalSmall {
    width: 50vw;
    height: auto;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &.modalMedium {
    width: 80%;
    height: 500px;
    max-width: 1100px;
    border-radius: 4px;
    overflow: hidden;
    margin: auto;
    position: fixed;
    top: 0;
    left: 65px;
    bottom: 0;
    right: 0;
    padding: 0;
    @media (max-width: 991px) {
      overflow: auto;
    }
  }
  &.modalLarge {
    width: 80%;
    height: 600px;
    max-height: 600px;
    max-width: 1050px;
    border-radius: 4px;
    overflow: hidden;
    margin: auto;
    position: fixed;
    top: 0;
    left: 65px;
    bottom: 0;
    right: 0;
    padding: 0;
    @media (max-width: 991px) {
      overflow: auto;
    }
  }
  img {
    margin: initial;
  }
  .close {
    position: absolute;
    top: 16px;
    right: 21px;
    cursor: pointer;
    z-index: 110;
  }
}

.previewOverlay {
  position: fixed;
  top: 0;
  left: 65px;
  width: calc(100vw - 65px);
  height: 100vh;
  background: rgba(0, 0, 0, 0.35);
  z-index: 101;
}
.detailTabs {
  > div > section {
    height: 200px;
    overflow-y: auto;
  }
}
.error {
  width: calc(100% - 65px);
  left: 65px;
  background: $primary-yellow !important;
  span {
    font-size: 1.8rem;
    font-weight: bold;
    text-align: center;
  }
  @media (max-width: 568px) {
    width: 100%;
    left: 0;
  }
}
.giftImage {
  position: relative;
  width: 50%;
  img {
    width: 100%;
    height: 100%;
    max-height: 478px;
  }
}
// open and close class handlers

:global .Closed {
  display: none;
}
:global .Open {
  display: block;
}

// Order Specific Styles
.orderView {
  .previewModal {
    position: relative;
    left: 0;
    top: 0;
    height: auto;
    background: #f7f7f7;
  }

  .cardAttrs {
    margin-bottom: 20px;
    div {
      width: 115px;
    }
  }
  .cardActions {
    margin-top: 25px;
    h4 {
      display: flex;
      font-size: 1.34rem;
      letter-spacing: 0.5px;
    }
    span {
      font-size: 1.598rem;
      flex-wrap: wrap;
      display: flex;
      width: 100%;
    }
  }
}

@media (max-width: 950px) {
  .previewSection {
    img {
      width: 250px;
      height: auto;
    }
  }
  .giftImage {
    img {
      height: 100%;
    }
  }
  .cardInfo {
    margin: 50px 40px;
  }
}
@media (max-width: 799px) {
  .previewModal {
    &.modalLarge {
      width: 90%;
      height: 100vh;
    }
    &.modalMedium {
      max-height: 600px;
      .cardInfo {
        width: 80%;
        margin: 0 auto;
      }
      .giftImage {
        position: relative;
        width: 50%;
        margin: 50px auto;
      }
    }
  }
  .flex {
    display: block;
  }
  .previewSection {
    display: block;
    padding: 20px;
    img {
      display: block;
      width: 80%;
      margin: 0 auto;
    }
  }
  .cardInfo {
    h4 {
      text-align: center;
      margin-bottom: 10px;
    }
    margin: 35px auto;
    max-width: 80%;
  }
}
@media (max-width: 680px) {
  .cardInfo {
    margin: 45px auto 0 auto;
    width: 100%;
  }
}

@media (max-width: 568px) {
  .previewModal {
    width: 100%;
    height: 100vh;
    left: 0;
    padding-bottom: 0;
    &.modalLarge {
      left: 0;
      top: 0;
      width: 100vw;
      max-height: 100vh;
      height: 100vh;
      .cardActions {
        position: fixed;
        display: flex;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 10px 20px 30px;
        background: #fff;
        box-shadow: 0 8px 22px 0 #757575;
        > a {
          position: relative;
          margin-left: auto;
        }
      }
    }
    &.modalMedium {
      width: 90vw;
      height: 90vh;
      left: 0;
    }
  }
  .previewOverlay {
    width: 100%;
    left: 0;
  }
  .cardInfo {
    max-width: 95%;
    text-align: left;
    padding-bottom: 82px;
    h4 {
      text-align: left;
    }
  }
}
@media (max-width: 445px) {
  .previewModal {
    &.modalLarge {
      .previewSection {
        padding: 20px;
        overflow-y: auto;
      }
    }
  }
}
