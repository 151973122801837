.mainContainer {
  height: 100%;
  .header {
    width: 100%;
    box-shadow: 5px 3px 20px rgba(136, 136, 136, 0.3);
    background: white;
    position: fixed;
    top: 0;
    z-index: 400;
  }
  .scrollableContainer {
    padding-top: 100px;
    overflow: hidden;
    position: relative;
  }
}
