.variationToggle {
  display: flex;
  min-width: auto;
  height: 35px;
  background: #fff;
  border-radius: 100px;
  margin-top: 50px;
  position: absolute;
  z-index: 1;
  bottom: -100px;
  transform: translateX(-50%);
  box-shadow: 0 15px 20px -15px rgba(125, 124, 124, 0.3),
    0 55px 50px -35px rgba(156, 156, 156, 0.3),
    0 85px 60px -25px rgba(255, 255, 255, 0.1);
  .variation {
    position: relative;
    width: 35px;
    height: 35px;
    display: inline-block;
    border: 2px solid transparent; // border color set in variationToggle.js
    border-radius: 100%;
    transform: scale(1.065);
    &:hover {
      cursor: pointer;
    }
    .variationColor {
      pointer-events: none;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
}
@media (max-width: 570px) {
  .variationToggle {
    display: flex;
    min-width: auto;
    height: 35px;
    background: #fff;
    border-radius: 100px;
    margin-top: 50px;
    position: absolute;
    z-index: 1;
    bottom: -115px;

    transform: translateX(-50%);
    box-shadow: 0 15px 20px -15px rgba(125, 124, 124, 0.3),
      0 55px 50px -35px rgba(156, 156, 156, 0.3),
      0 85px 60px -25px rgba(255, 255, 255, 0.1);
    .variation {
      position: relative;
      width: 35px;
      height: 35px;
      display: inline-block;
      border: 2px solid transparent; // border color set in variationToggle.js
      border-radius: 100%;
      transform: scale(1.065);
      &:hover {
        cursor: pointer;
      }
      .variationColor {
        pointer-events: none;
        width: 10px;
        height: 10px;
        border-radius: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
