@import '../../../app/styles/variables';

// card sizing aspect-ratio mixin
@mixin aspect-ratio($width, $height) {
  position: relative;
  &::before {
    display: block;
    content: '\a0';
    width: 100%;
    height: 0;
    padding-top: calc($height / $width) * 100%;
  }
  > .panelContent {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.panelContent {
  position: relative;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

// Fullbleed card : look in sliderButtons.scss for flex positioning on panels

.fullBleed {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &.horizontalCard {
    flex-direction: column;
    .panelContainer {
      margin: 1px auto;
    }
  }
  .fullBleedPanels {
    position: relative;
    display: flex;
    justify-content: center;
    &.horizontalCard {
      flex-direction: column;
      align-items: center;
    }
    .options {
      svg path {
        fill: #fff;
      }
    }
  }
  .fullBleedPanelContainer {
    width: 100%;
    height: 100%;
    position: absolute;
  }
}

.bottomRightBarCode {
  position: absolute;
  bottom: 0px;
  right: 40px;
  .barCode {
    img {
      width: 100%;
      user-select: none;
    }
  }
}
.postCardPosition {
  right: 50%;
  transform: translateX(-25%);
  width: 50vmin !important;
}
// Card aspect-ratio's
.panel {
  background: #fff;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.175);
}
.twoPanelPortrait {
  background: #fff;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.175);
  @include aspect-ratio(5, 7);
}
.twoPanelLandscape {
  background: #fff;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.175);
  @include aspect-ratio(7, 5);
}
.postCardPortrait {
  background: #fff;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.175);
  @include aspect-ratio(4.25, 6);
}
.postCardSleeve {
  width: 61.25%;
  box-shadow: 11px 8px 18px 1px rgba(0, 0, 0, 0.175);
  background-color: #fff;
  position: absolute;
  right: -61.25%;
  top: 0;
  height: 100%;
  font-size: 12px;
  &:before {
    content: '';
    position: absolute;
    left: 0;
    width: 1px;
    height: 82%;
    border-radius: 30px;
    background-color: #6f8394;
    top: 50%;
    transform: translateY(-50%);
  }
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    box-shadow: 0 -5px 5px -5px #f766b1,
      // Top
      0 5px 5px -5px #f766b1,
      // Right
      0 5px 5px -5px #f766b1; // Bottom
  }
  .stamp {
    width: 100%;
    img {
      width: 100%;
      padding: 20px;
    }
  }
  .sleeveReturnAddress {
    position: relative;
    bottom: 25px;
    padding-left: 15px;
    p {
      font-size: 1rem;
      line-height: 16px;
    }
  }
  .sleeveToAddress {
    position: relative;
    padding-left: 15px;
    top: 25px;
    p {
      font-size: 1rem;
      line-height: 16px;
    }
  }
}
.postCardLandscape {
  background: #fff;
  box-shadow: 0 8px 22px 0 rgba(0, 0, 0, 0.175);
  @include aspect-ratio(6, 4.25);
}

.backPanel {
  a,
  p,
  span,
  div,
  font,
  u {
    font-size: 10px !important;
    color: #000000 !important;
    text-decoration: none !important;
  }
}
.defaultAddress {
  p {
    font-size: 11px;
    line-height: 20px;
    margin: 0;
    margin-left: 7px;
  }
}
.selectedPanel {
  box-shadow: 1px 2px 8px 0 #f766b1;
}
.imageSpanActions {
  display: flex;
  position: absolute;
  left: 50%;
  top: 15px;
  transform: translateX(-50%);
  z-index: 102;
}
.spanningAction {
  margin-right: 10px;
}
