.checkbox {
  position: relative;
  appearance: none;
  border: 2px solid #e5e7eb;
  border-radius: 0.6rem;
  width: 1.5rem;
  height: 1.5rem;
  background-color: white;

  &:checked {
    background-color: #56a8e2;
    border-color: #56a8e2;

    &::before {
      content: '✓';
      font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto,
        Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
      display: block;
      color: white;
      position: absolute;
      transform: translate(-50%, -50%);
      left: 50%;
      top: 50%;
    }
  }
}

.input {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;

  &::placeholder {
    color: #9CA3AF;
    font-size: 16px;
    font-family: inherit; 
  }
}