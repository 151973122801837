.dialogContainer {
  max-height: 400px !important;
  .innerContainer {
    span {
      color: #000000;
      letter-spacing: 1px;
    }
    .close {
      position: absolute;
      top: 7px;
      right: 11px;
    }
    .body {
      display: flex;
      justify-content: space-between;
      .selector {
        width: 100%;
        padding-left: 50px;
      }
    }
  }
}

@media (max-width: 414px) {
  .dialogContainer {
    width: 100% !important;
    transform: none !important;
    max-height: 100%;
    height: 40px;
    .innerContainer {
      .body {
        flex-direction: column;
        align-items: center;
        .selector {
          width: 100%;
          padding: 25px 0;
        }
      }
    }
  }
}
@media (max-height: 600px) {
  .dialogContainer {
    .dialogBody {
      max-height: 330px !important;
      width: 100%;
    }
  }
}
