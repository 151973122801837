@import '../../../chrome/styles/variables';

.orderHeader {
  position: relative;
  height: 60px;
  width: 100%;
  display: flex;
  align-items: center;
  background: $white;
  box-shadow: 0 2px 28px -2px #dedede;
  z-index: 105;
  h5 {
    display: block;
    position: relative;
    margin-left: 20px;
    font-weight: 300;
    color: $text-color;
  }
  .inputOrderName {
    width: 200px;
    margin-left: 10px;
    input {
      cursor: pointer;
      font-weight: 400;
      letter-spacing: 0.5px;
      color: $primary-red;
      padding: 0;
    }
  }
  .orderInfo {
    display: flex;
    margin-left: auto;
    margin-right: 25px;
    align-items: flex-end;
    .orderWarnings {
      margin-right: 15px;
      cursor: pointer;
      display: flex;
    }
    .addressCount {
      cursor: pointer;
      display: flex;
      flex-wrap: nowrap;
      align-items: center;
    }
    svg {
      width: 20px;
      path {
        fill: $black;
      }
    }
  }
  .orderTotal {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;
    svg {
      width: 20px;
      position: relative;
      bottom: 1px;
      left: 7px;
      path {
        fill: $primary-red;
      }
    }
  }
  .badge {
    position: relative;
    bottom: 5px;
    right: 2px;
    width: 20px;
    height: 20px;
    background-image: linear-gradient(115deg, #ff5689, #e687ff);
    display: flex;
    justify-content: center;
    align-items: center;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    border-radius: 100%;
    box-shadow: $shadows;
    p {
      font-size: 1em;
    }
  }
  .popover {
    color: #ff5689 !important;
    top: 60 !important;
    right: 0 !important;
  }

  @media (max-width: 568px) {
    height: 90px;
  }
}

@media (max-width: 428px) {
  .campaignButton {
    display: none;
  }
}
