.modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-image: linear-gradient(to bottom, #f8f8f8b3, #f2f2f2);
  z-index: 200;
}
