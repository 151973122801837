@import '../../../chrome/styles/variables';

.detailDrawer {
  top: 0px;
  width: calc(100vw - 65px);
  height: 100vh;
  min-height: 150px;
  z-index: 120;
  overflow: hidden;
  .drawerHeader {
    display: flex;
    padding-top: 15px;
    h6 {
      margin-left: 10px;
    }
  }
  .drawerAction {
    border-radius: 100px;
    padding: 4px 15px;
    background-color: #5b676d;
    span {
      cursor: pointer;
      font-weight: 600;
      color: $white;
      font-size: 11px;
    }
    svg {
      margin-right: 5px !important;
      path {
        fill: $white;
      }
    }
  }
}

.detailDrawerSparse {
  top: 65px;
  height: calc(100vh + 65px);
}

@media (max-width: 568px) {
  .detailDrawer {
    width: 100%;
  }
}
