.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 8px;
    h6 {
      font-weight: 400;
    }
    .actions {
      display: flex;
    }
  }
  .giftContainer {
    display: flex;
    .gift {
      height: 340px;
      width: 340px;
      border-radius: 2px;
    }
    h6 {
      margin-bottom: 8px;
    }
    .giftActions {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      width: 100%;
      p {
        font-size: 1.5rem;
        text-align: center;
      }
      .button {
        margin-top: 60px;
      }
    }
  }
  .addressPreview {
    display: flex;
    flex-direction: column;
  }
}
@media (max-width: 562px) {
  .giftContainer {
    flex-direction: column;
    .gift {
      height: 280px;
      width: 280px;
    }
    .button {
      margin-top: 50px !important;
    }
  }
}
