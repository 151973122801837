$font-stack: 'Open Sans', sans-serif;

.shareBody {
  display: flex;
  flex-wrap: wrap;
}
.contacts {
  width: 100%;
  h5 {
    padding-left: 12px;
    color: #3b4045;
  }
  .contactList {
    width: 100%;
    max-height: 300px;
    overflow-y: auto;
  }
}
.shareForm {
  width: 1000%;
  display: flex;
  input {
    font-family: $font-stack;
    font-size: 13px;
    font-weight: bold;
    letter-spacing: 0.5px;
  }
  input[type='text'] {
    align-items: center;
    background: white;
    width: calc(100% - 45px);
    box-shadow: 0 1px 2px 1px #d4d4d4;
    margin: 5px 0;
    border-radius: 2px;
    height: 37.5px;
  }
  input[type='submit'] {
    color: #fff;
    height: 37.5px;
    position: relative;
    top: 4px;
    background: #333;
    box-sizing: border-box;
    padding: 6px 20px;
    border-radius: 0;
    cursor: pointer;
    box-shadow: 0 2px 5px 0 #d4d4d4;
    border: 1px solid #333;
  }
}

@media (max-width: 568px) {
  .shareContainer {
    width: 100% !important;
    transform: translate(0px, 0px) !important;
  }
  .shareBody {
    flex-direction: column;
    .contacts {
      width: 100%;
      order: 1;
    }
    .shareForm {
      width: 100%;
      margin-bottom: 25px;
    }
  }
}
