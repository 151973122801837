.configWindow {
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-image: linear-gradient(115deg, #e687ff, #ff5689);
  z-index: 1000;
  overflow-y: auto;
}
