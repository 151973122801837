.formContainer {
  color: black;
  max-height: calc(100vh - 225px) !important;
  transform: translate(0px, 0px) !important;

  .formInner {
    .flex {
      display: flex;
      justify-content: space-evenly;
    }
    .actions {
      display: flex;
      width: 100%;
      justify-content: flex-end;
    }
  }
}
.close {
  position: absolute;
  right: 12px;
  top: 12px;
}
.campaignOverlay {
  background: white;
  position: fixed;
  top: 0;
  bottom: 40px;
  left: 0;
  right: 40px;
  opacity: 0.7;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  > span {
    font-weight: bold;
  }
}
.textField {
  color: black;
}
@media (max-width: 750px) {
  // [data-reactroot] {
  //   padding-top: 0px !important;
  // }
  .flex {
    flex-direction: column;
    align-items: center;
    div:first-child {
      margin-bottom: 15px;
    }
    &.groupView {
      align-items: left;
      div:first-child {
        margin-bottom: 15px;
      }
    }
  }
}
