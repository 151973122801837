@import '../../../chrome/styles/variables';

$dark-gray: #3b4045;

.body {
  display: flex;
  justify-content: center;
  margin-top: 16px;

  .selectorsBlock {
    width: 45%;
    margin-right: 35px;
    display: flex;
    flex-direction: column;
    align-items: center;

    .groupSelectContainer {
      width: 100%;
      margin-top: 12px;
      background-color: #f6f8fa;
      border-radius: 4px;
    }
  }

  .selectedContacts {
    width: 45%;

    h5 {
      font-size: 18px;
      font-weight: 400;
    }
  }
}

.newGroupContainer {
  margin-top: 15px;

  .inputLabel {
    color: #6f8394;
    font-weight: 500;
  }

  .input {
    color: #3b4045;
  }

  .textField {
    margin-top: -10px;
  }
}

.container {
  .close {
    cursor: pointer;
    position: absolute;
    top: 7px;
    right: 12px;
  }

  .title {
    color: $dark-gray;
    margin: 27px 0 5px 0;
    text-align: center;
  }

  .contactSelector {
    padding: 0;
    overflow-y: auto;
    max-height: 368px;
    border-radius: $card-radius;
    background-color: #f6f8fa;
  }

  .contactLi {
    display: flex;
    align-items: center;
    transition: all 0.3s ease 0s;
    cursor: pointer;
    &:hover {
      background-color: #e9ecef;
    }
  }

  .actions {
    padding-top: 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
}

.memberList {
  margin-top: 15px;

  span {
    color: $dark-gray;
  }

  .list {
    max-height: 150px;
    overflow-y: auto;
  }
}

//Media Queries
@media (max-width: 783px) {
  .modalContent {
    width: 100% !important;
    transform: translate(0px, 0px) !important;
  }
}

@media (max-width: 568px) {
  .modalContent {
    .body {
      flex-direction: column;

      .selectorsBlock {
        width: 100%;

        .newGroupContainer {
          width: 100%;
        }
      }

      .selectedContacts {
        margin-top: 10px;
        width: 100%;
      }

      .textField {
        width: 100% !important;
      }
    }
  }
}
