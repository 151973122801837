.buttonInserts {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  padding: 0 10px;
  > button {
    margin-bottom: 12px;
  }
  > div {
    margin-right: 8px !important;
    height: 29px !important;
    width: 115px !important;
    > div {
      padding: 0 !important;
    }
    span {
      font-size: 9px !important;
    }
  }
}

@media (max-width: 568px) {
  .buttonInserts {
    justify-content: center;
    > div {
      margin-right: 5px !important;
    }
  }
}
