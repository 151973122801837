.subList {
  display: flex;
  width: 100%;
  max-height: 250px;
  flex: 1;
  align-items: stretch;
  overflow-y: auto;
  flex-direction: column;
  row-gap: 4px;
  padding: 0px 4px 0px 0px;
}

.subList::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

.subList::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.subList::-webkit-scrollbar-thumb {
  background-color: #afafaf;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.subList::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
