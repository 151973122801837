$text-color: #6f8394;

.dashboardNav {
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow-y: auto;
  height: 100%;

  ul {
    margin: 0;
  }
  li {
    position: relative;
    display: flex;
    list-style: none;
    padding: 0 32px 0;
    font-size: 0.85rem;
    font-weight: 500;
    letter-spacing: 0.5px;
    margin-bottom: 25px;
    cursor: pointer;
    a {
      color: $text-color;
      &.active {
        border-bottom: 1px solid #f5326f;
        padding-bottom: 6px;
        color: #f5326f;
      }
    }
  }
  .categoryTitle {
    padding: 0 16px 24px;
    margin: 0;
  }
  .logo {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 25px 0 50px 0px;
  }
  .linkIcon {
    margin-right: 6px;
    position: relative;
    bottom: 2px;
  }
  .backToEditor {
    flex-direction: flex-end;
  }
}

.dashboardNav::-webkit-scrollbar {
  width: 0;
  background: transparent;
}

.dashboardNav {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.notificationBadge {
  color: #fff;
  font-weight: 500;
  border-radius: 100px;
  position: relative;
  height: 22px;
  width: 22px;
  line-height: 22px;
  font-size: 10px;
  z-index: 1000;
  vertical-align: middle;
  text-align: center;
  background-image: linear-gradient(115deg, #ff5689, #e687ff);
  display: inline-block;
}
