.filterButton {
  background: none !important;
  box-shadow: none !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .badge {
    background: white;
    width: 100px;
    height: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    box-shadow: 0 4px 5px 0 #d4d4d4;
    margin-bottom: 22px;
  }
}
