.sectionTitle {
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  h5 {
    font-family: 'Dosis';
    color: rgba(0, 0, 0, 0.68);
    font-weight: 500;
    text-transform: uppercase !important;
    font-size: 16px;
    letter-spacing: 0.85px;
  }

  .buttonLabel {
    line-height: 26px;
  }
}
.componentContainer {
  p {
    font-weight: 500;
  }
  position: relative;
}
