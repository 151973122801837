.closeXHoverParent {
  position: relative;
  .closeXButton {
    visibility: hidden;
    position: absolute;
    border-radius: 50%;
    background-color: #f7f7f7;
    top: 2px;
    right: 2px;
    width: 25px;
    height: 25px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    border: none;
    cursor: pointer;
  }
  &:hover {
    .closeXButton {
      visibility: visible;
    }
  }
}
