.overlay {
  z-index: 3090;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  opacity: 0.5;
}
.modal {
  max-height: 100%;
  width: 1200px;
  max-width: 100%;
  display: inline-block;
  z-index: 4000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  .closeButton {
    position: fixed;
    top: 10px;
    right: 15px;
    z-index: 1000;
    cursor: pointer;
  }
  .modalContent {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    overflow-y: auto;
    h1 {
      font-size: 2.2rem;
      line-height: 2.2rem;
      margin-bottom: 10px;
      padding: 0 10px;
    }
    .imageContainer {
      height: 500px;
      img {
        height: 100%;
        display: flex;
        border-radius: 4px 0 0 4px;
      }
    }
    .rightContainer {
      padding: 25px;
      flex-grow: 1;
      .content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        height: 100%;
      }
    }
  }
  .body {
    flex-grow: 1;
    max-height: 292px;
    min-height: 80px;
    overflow-y: auto;
    padding: 10px;
  }
  .footer {
    padding: 0 10px;
  }
}

@media (max-width: 1000px) {
  .modal {
    height: 100%;
    .modalContent {
      flex-direction: column;
      align-items: center;
      height: 100%;
      padding-bottom: 25px;
      h1 {
        text-align: center;
      }
    }
    .imageContainer {
      margin-top: 25px;
    }
    .rightContainer {
      width: 100%;
      height: 45%;
    }
    .body {
      max-height: 265px;
    }
  }
}

@media (max-width: 1200px) {
  .modal {
    .imageContainer {
      height: 320px !important;
    }
  }
}

@media (max-width: 540px) {
  .modal {
    .imageContainer {
      height: 200px !important;
    }
  }
}

@media (max-width: 400px) {
  .modal {
    .imageContainer {
      height: 160px !important;
    }
  }
}

// animations
.slide {
  .enter {
    z-index: 1000;
    transform: translate(-100%);
  }
  .enter.enterActive {
    z-index: 1000;
    transform: translate(0%);
    transition: all 200ms ease-in;
    -webkit-transition: all 200ms ease-in;
    -moz-transition: all 200ms ease-in;
    -ms-transition: all 200ms ease-in;
    -o-transition: all 200ms ease-in;
  }
  .leave {
    z-index: 1000;
    transform: translate(-50%, -50%);
  }
  .leave.leaveActive {
    z-index: 1000;
    transform: translate(100%);
    transition: all 200ms ease-out;
    -webkit-transition: all 200ms ease-out;
    -moz-transition: all 200ms ease-out;
    -ms-transition: all 200ms ease-out;
    -o-transition: all 200ms ease-out;
  }
}

.fade {
  .enter {
    opacity: 0.01;
    width: 0px;
    z-index: 1000;
  }
  .enter.enterActive {
    opacity: 1;
    width: 100px;
    transition: 200ms;
    z-index: 1000;
  }
  .leave {
    opacity: 1;
    width: 100px;
    z-index: 1000;
  }
  .leave.leaveActive {
    opacity: 0.01;
    width: 0px;
    transition: 200ms;
    z-index: 1000;
  }
}
