.container {
  display: flex;
  padding: 5px;
  .details {
    display: flex;
    flex-flow: column wrap;
    padding-left: 2rem;
    min-width: 50%;
    justify-content: center;
    .contactName {
      font-size: 3rem;
      letter-spacing: 1px;
      font-weight: 600;
      &:after {
        content: '';
        width: 100%;
        height: 1px;
        bottom: 0;
        left: 0;
        display: block;
        margin-top: 3px;
        background: #b3b3b3;
      }
    }
    .contactInfo {
      display: flex;
      flex-flow: row wrap;
      justify-content: space-between;
      padding-top: 5px;
      > div {
        margin-right: 15px;
        h6,
        span {
          display: block;
          text-align: left;
        }
        h6 {
          font-size: 12px;
          letter-spacing: 1.2px;
        }
        span {
          cursor: initial;
          letter-spacing: 0.5px;
          font-weight: 600;
        }
        a {
          color: #ff5689;
          text-decoration: none;
          letter-spacing: 0.5px;
          font-weight: 700;
        }

        a[href^='tel:'],
        a[href^='mailto:'] {
          color: #ff5689;
          text-decoration: none;
        }
        a[href^='tel:']:before {
          content: '\260e';
          margin-right: 0.5em;
        }
        a[href^='mailto:']:before {
          content: '\2709';
          margin-right: 0.5em;
        }
      }
    }
  }
}

@media (max-width: 414px) {
  .avatar {
    display: none;
  }
  .details {
    padding: 20px 0 0 0 !important;
    width: 100% !important;
    .contactInfo {
      display: flex;
      width: 100%;
      flex-flow: row wrap;
      justify-content: space-between;
      > div {
        display: flex;
        width: 100%;
        flex-direction: row;
        justify-content: space-between;

        h6 {
          font-size: 1rem;
          letter-spacing: 1.2px;
        }
        span {
          cursor: initial;
          letter-spacing: 0.5px;
          font-weight: 700;
        }
        a {
          color: #ff5689;
          text-decoration: none;
          letter-spacing: 0.5px;
          font-weight: 700;
        }
      }
    }
  }
}
