@import '../styles/variables';

.uiCard {
  background-color: #fff;
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0 2px 14px 0 #eaeaea;
  margin-bottom: 10px;
}
.uiCardHeader {
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-content: center;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  border-bottom: 2px solid #f7f7f7;

  h5 {
    color: $header-color;
  }

  button {
    margin-bottom: 5px;
  }
}
.uiCardContent {
  width: 100%;
}

@media (max-width: 770px) {
  .uiCardHeader {
    flex-flow: column;
    padding-bottom: 1rem;
    .uiCardHeaderAction {
      margin-top: 0.9rem;
    }
  }
}
