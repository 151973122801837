.selectAllHeader {
  cursor: pointer;
  text-decoration: underline;
}

.addressBlockWrapper {
  display: flex;
}
.addressBlock {
  display: flex;
  flex-direction: column;
}
.cursorDefault {
  cursor: default;
  span {
    cursor: default;
  }
}
.cursorPointer {
  cursor: pointer;
  span {
    cursor: pointer;
  }
}
.cardActions {
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
}
.cardPreview {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  box-shadow: 0 4px 10px 0 #bdbaba;
}
.giftPreview {
  position: absolute;
  bottom: -5px;
  right: -20px;
  height: 65px;
  width: 65px;
  border-radius: 8px;
  background-size: cover;
  background-position: center;
  box-shadow: 0 4px 10px 0 #bdbaba;
}
.tableSpacer {
  height: 2px;
}
