.loadingContainer {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1000;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  h3 {
    width: 100%;
    text-align: center;
    margin-top: 25px;
    color: rgba(0, 0, 0, 0.65);
  }
}
.loadingIndicator,
.loadingIndicator * {
  box-sizing: border-box;
}

.loadingIndicator {
  height: 15px;
  width: calc((15px + 15px * 1.125) * 3);
  display: flex;
  align-items: center;
  justify-content: center;
  .circle {
    height: 15px;
    width: 15px;
    margin-left: calc(15px * 1.125);
    transform: rotate(45deg);
    border-radius: 10%;
    border: 3px solid #ff1d5e;
    overflow: hidden;
    background: transparent;
    animation: loading-animation 1200ms linear infinite;
    &:nth-child(1) {
      animation-delay: calc(150ms * 1);
      margin-left: 0;
    }
    &:nth-child(2) {
      animation-delay: calc(150ms * 2);
    }
    &:nth-child(3) {
      animation-delay: calc(150ms * 3);
    }
  }
}

@keyframes loading-animation {
  0% {
    border-radius: 10%;
  }

  17.5% {
    border-radius: 10%;
  }

  50% {
    border-radius: 100%;
  }

  93.5% {
    border-radius: 10%;
  }

  100% {
    border-radius: 10%;
  }
}
