@import '../../../app/styles/variables';

.tableSpacer {
  width: 100%;
  height: 20px;
}

.addressCheckBox {
  margin-bottom: 0;
  margin-left: 13px;
}

.addressDisplay {
  tr {
    box-shadow: 0 1px 8px 1px #dedede;
    &.tableSpacer {
      box-shadow: none;
    }
  }
}
.addressBook,
.overFlow {
  height: 100%;
  overflow-y: auto;
  padding-top: 25px;
  margin-top: 10px;
  &.addressPadding {
    padding: 0 35px 80px 0;
  }
  .addressSearch {
    margin: 35px auto;
    padding-bottom: 0px;
    top: 0;
  }
  .addressSearchContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
