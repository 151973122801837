.managerContainer {
  height: 100vh;
  overflow: hidden;
  background-color: #f7f7f7;
  display: flex;
  flex-direction: column;
  .body {
    flex: 1 1 auto;
    position: relative;
    margin: 0 50px;
    .tableContainer {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      overflow: auto;
    }
  }
  .pagination {
    margin: 0 50px;
  }
}
.tableSpacer {
  height: 2px;
}

@media (max-width: 1110px) {
  .managerContainer {
    .body {
      margin-top: 30px;
    }
  }
}

@media (max-width: 783px) {
  .managerContainer {
    height: calc(100vh - 65px);
    .body {
      margin-top: 10px;
      padding: 0 5px !important;
    }
  }
}
@media (max-width: 568px) {
  .managerContainer {
    .body {
      margin: 0 15px;
    }
    .pagination {
      margin: 0 15px;
    }
  }
}

.headerContainer {
  position: relative;
  height: 90px;
  width: 100%;
  display: flex;
  flex-flow: row;
  padding: 0 25px;
  align-items: center;
  box-shadow: 0 2px 28px -2px #dedede;
  z-index: 105;

  .actionButtons {
    display: flex;
    flex-flow: row;
    .importButton {
      position: relative;
    }
    .contextButton {
      margin-left: 1.5rem;
    }
  }

  .searchBar {
    flex-grow: 3;
    max-width: 1000px;

    .searchFilter {
      padding-bottom: 0 !important;
      width: 100% !important;
    }
  }

  .addButton {
    display: flex;
    flex-direction: row;
    > button {
      background-image: linear-gradient(115deg, #ff5689, #e687ff) !important;
    }
  }
}

@media (max-width: 1110px) {
  .headerContainer {
    .actionButtons {
      display: none;
    }
    .searchBar {
      width: 80%;
      left: 0px !important;
    }
  }
}
@media (max-width: 768px) {
  .headerContainer {
    height: auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px;
    box-shadow: none;
    .importButton {
      display: none;
    }
    .contextButton {
      display: none;
    }
    .searchBar {
      width: 100%;
    }
    .addButton {
      width: 100%;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: auto;
      top: 0;
      right: 0;
      padding-top: 16px;
    }
  }
}
