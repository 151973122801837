@import '../styles/variables';

$border: 1px solid rgba(0, 0, 0, 0.27);

.invoiceContainer {
  padding-top: 15px;
  color: #333;
  margin: auto;
  svg {
    pointer-events: all;
  }
  span,
  h5 {
    font-size: 15px;
    cursor: default;
  }
  .header {
    flex-direction: column;
    justify-content: space-between;
    align-items: top;
    margin-bottom: 30px;
  }
  .details {
    display: flex;
    flex-direction: column;
    .detailsHeader {
      display: flex;
      justify-content: space-between;
      border-bottom: $border;
      span {
        font-weight: 400;
      }
      span:nth-child(2) {
        padding-right: 15px;
      }
    }
    .recipients {
      padding: 8px 15px 8px 0;
      text-align: right;
      justify-content: flex-end;
      display: flex;
      h5 {
        font-weight: bold;
        font-size: 1.5rem;
        padding: 5px;
        border-bottom: $border;
      }
    }
    .totals {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      padding: 15px 0px 8px 0;
      span {
        font-weight: 600;
      }
      > h5 {
        padding-left: 20px;
      }
    }
    .grandTotal {
      display: flex;
      justify-content: flex-end;
      flex-direction: column;
      border-top: $border;
      .totalItem {
        display: flex;
        padding: 15px 15px 0 0px;
        width: 100%;
        justify-content: flex-end;
        border-top: none;
        align-items: center;
        & > span {
          margin-left: 10px;
          font-weight: 600;
          font-size: 20px;
          @media (max-width: 568px) {
            font-size: 18px;
          }
        }
        & > h5 {
          @media (max-width: 568px) {
            font-size: 16px;
          }
          padding-left: 20px;
          font-weight: 700;
          font-size: 18px;
        }
      }
    }
  }
}

.cell {
  margin: 0 15px 0 0;
}
.cell.card {
  width: 50%;
  @media (max-width: 568px) {
    width: 70%;
  }
}
.cell.price {
  width: 30%;
  @media (max-width: 568px) {
    width: 100%;
  }
}
.cell.total {
  width: 20%;
  @media (max-width: 568px) {
    :before {
      content: 'Total: ';
    }
    width: 100%;
  }
}
.priceTotalContainer {
  width: 50%;
  display: flex;
  justify-content: space-between;
  @media (max-width: 568px) {
    flex-direction: column;
    width: 30%;
    span:nth-child(1) {
      :after {
        content: ' / Total';
        display: block;
      }
    }
    span:nth-child(2) {
      display: none;
    }
  }
}

.line {
  .cardLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0 5px 0px;
    border-bottom: $border;
    .cardThumbnail {
      box-shadow: $shadows;
      background-size: cover;
      &.landscape {
        width: 100px;
        height: 71.5px;
      }
      &.portrait {
        height: 100px;
        width: 71.5px;
      }
    }
    .cardTitle {
      padding-left: 7px;
      text-overflow: ellipsis;
      width: 50%;
    }
  }
  .giftLine {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 9px 0 5px 0px;
    border-bottom: $border;
    .giftThumbnail {
      border: $border;
      background-size: cover;
      width: 65px;
      height: 65px;
    }
    .giftTitle {
      padding-left: 7px;
      text-overflow: ellipsis;
    }
  }
}

.billingAddr {
  display: flex;
  flex-direction: column;
  span:empty {
    display: none;
  }
  span {
    line-height: 20px;
  }
  span:nth-child(1) {
    font-weight: 400;
    line-height: 25px;
  }
}

.neededCurrencyGrid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-row-gap: 7px;
  margin-top: 50px;
  .neededTotal {
    font-weight: 600;
  }
  .expenseInfo {
    display: flex;
    align-items: center;
  }
}

.neededCurrency2ColumnGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-row-gap: 7px;
  margin-top: 50px;
  .neededTotal {
    font-weight: 600;
  }
  .expenseInfo {
    display: flex;
    align-items: center;
  }
}
