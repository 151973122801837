.reminder {
  display: flex;
  justify-content: space-between;
  background: #fff;
  margin: 10px 0;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 #dedede;
  padding-right: 20px;

  .leftContainer {
    padding: 15px 0 10px 0;
    display: flex;
    width: 120px;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    text-align: center;

    .date {
      padding: 1px 0;
      border-top-left-radius: 3px;
      border-bottom-left-radius: 3px;
      color: #fff;
      width: 7%;
      min-width: 83px;
      max-width: 140px;
      font-weight: 500;
      background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }

  .actionsContainer {
    display: flex;
    justify-content: space-between;
    margin: 10px 0px 10px auto;
    min-width: 290px;
  }

  .body {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    width: 100%;

    .details {
      display: flex;
      width: 20%;
      flex-direction: column;

      span:first-of-type {
        font-weight: 500;
        font-size: 2rem;
      }
    }
    .sendButton {
      margin-left: auto;
    }
  }
  .dismissButton {
    display: flex;
    align-items: center;
    padding: 20px;
    cursor: pointer;
  }
}

@media (max-width: 878px) {
  .body {
    flex-direction: column;
    align-items: center;
    padding: 15px;
    .details {
      width: 100% !important;
    }
    .description {
      width: 100% !important;
      padding: 15px 0 !important;
    }
    .sendButton {
      margin: auto !important;
      width: 126px !important;
    }
  }
}

@media (max-width: 347px) {
  .reminder {
    position: relative;
    .dismissButton {
      position: absolute;
      top: -9px;
      right: -9px;
    }
  }
}
