.campaignEntry {
  height: 100vh;
  .pendingOrders {
    position: absolute;
    display: flex;
    width: 100%;
  }
  .campaign {
    position: absolute;
    bottom: 250px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 100;
    h3 {
      text-align: center;
      margin-bottom: 100px;
    }
    .campaignInput {
      display: flex;
      margin-bottom: 200px;
      justify-content: center;
      align-items: center;
      > div {
        margin-right: 15px;
      }
    }
    .textField {
      color: #333;
      font-weight: 500;
    }
  }
  .card {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 200px;
    height: 300px;
    background: #fff;
    box-shadow: 0 4px 20px 0 rgba(242, 79, 189, 0.16);
    border-radius: 4px;
    z-index: 100;
    transition: 0.5s cubic-bezier(0.4, 0.4, 0, 1);
    margin-bottom: -70px;
    cursor: pointer;
    svg path {
      fill: #f262b1;
    }
    &:hover {
      transform: translateY(-6px);
      box-shadow: 0 20px 60px 0 rgba(242, 79, 189, 0.26);
    }
  }
}

.bottomGradient {
  #welcomeGradient {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    z-index: 1;
    transform-origin: bottom center;
    transition: 1s cubic bezier(0.29, 0.17, 0, 1);
  }
  position: fixed;
  bottom: 0;
  height: 100vh;
  width: 100vh;
}

@media (max-width: 568px) {
  .campaignEntry {
    .campaign {
      bottom: 50px;
      .campaignInput {
        flex-direction: column;
        > div {
          margin-top: 20px;
        }
      }
    }
  }
}
