@import '../../../chrome/styles/variables';

.ribbon {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: 100%;
  position: relative;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 0 0 20px 30px;
}

.addContainer {
  padding: 0 30px 0 50px;
  border-left: 1px solid rgba(158, 158, 158, 0.5);
  .addFlex {
    display: flex;
    flex-direction: column;
    align-items: center;
    .addButton {
      border: none;
      margin: 10px 0;
      border-color: #bfbdbd;
      width: 90px;
      height: 125px;
      background: white;
      box-shadow: $shadows-hover;
      cursor: pointer;
      &:hover {
        transform: translateY(-6px);
        box-shadow: $shadows-hover;
        transition: 0.3s;
      }
    }
  }
}

.closeButton {
  visibility: hidden;
  position: absolute;
  border-radius: 50%;
  background-color: #f7f7f7;
  top: 2px;
  right: 2px;
  width: 25px;
  height: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  cursor: pointer;
  &:hover {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
}
.cardContainer {
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  align-items: center;
  .delayDescription {
    h6 {
      font-weight: 500;
      font-size: 1.375rem;
    }
  }
  &.active {
    .gift {
      border: none;
    }
    .delayDescription {
      transition: 0.3s;
      color: #fe5990;
      > h6 {
        font-weight: 600;
      }
    }
  }
  &:hover {
    .portrait {
      transform: translateY(-6px);
      box-shadow: $shadows-hover;
      opacity: 1;
      transition: 0.3s;
    }
    .landscape {
      transform: translateY(-6px);
      box-shadow: $shadows-hover;
      opacity: 1;
      transition: 0.3s;
    }
  }
}

.card {
  position: relative;
  min-height: 125.19px;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 10px 0 10px 0;
  .gift {
    position: absolute;
    bottom: 2px;
    right: -8px;
    width: 65px !important;
    height: 65px;
    border-radius: 8px;
    background-size: cover;
    box-shadow: 0 4px 9px 0 rgba(0, 0, 0, 0.3), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  }
  .active {
    border: 2px solid #fe5990;
    box-shadow: 0 4px 10px 0 #bdbaba;
    transition: 1s;
  }
  .portrait {
    position: relative;
    width: 89.4px;
    height: 125.2px;
    background-size: cover;
  }
  .portrait + .gift {
    bottom: -8px;
  }
  .landscape {
    position: relative;
    width: 125.2px;
    height: 89.4px;
    background-size: cover;
  }
  &:hover {
    .closeButton {
      visibility: visible;
    }
  }
}
