.minimalTextBar {
  height: 100%;
}
.controlContainer {
  position: relative;
  display: flex;
  height: 300px;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  overflow-x: auto;
  padding-bottom: 5px;
  &.colorPicker {
    padding-top: 14px;
    height: 100%x;
  }
  div {
    h5 {
      margin-top: 0;
      margin-left: 12px;
    }
  }
  &.fullView {
    // Full view font list
    height: calc(50vh - 10px);
    overflow-y: auto;
    overflow-x: hidden;
    > div {
      width: 100%;
      // Active font in font lost
      h6 {
        position: fixed !important;
        bottom: 0 !important;
        top: initial !important;
        width: 100% !important;
        background-color: #ff059f;

        z-index: 100;
      }
      ul {
        height: 100vh !important;
        padding-top: 25px !important;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        li {
          &:last-child {
            padding-bottom: 75px !important;
          }
        }
      }
    }
  }
}
.flexRow {
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: row;
  flex-wrap: nowrap;
  overflow-x: scroll;
  justify-content: center;
  align-items: center;
  padding-bottom: 0 !important;
  // Color Picker overrides
  > div {
    width: 100% !important;
    flex-wrap: nowrap !important;
    overflow-x: auto !important;
    margin-right: initial !important;
    position: absolute !important;
    left: 0 !important;
    padding-left: 15px !important;
    padding: 15px;
    height: 100%;
  }
}
