.componentContainer {
  .sectionHeader {
    margin-bottom: 10px;
    padding-left: 5px;
  }
  .backgroundImageActions {
    display: flex;
    justify-content: center;
    padding: 16px 0;
    flex-wrap: wrap;
    .spanningAction {
      width: 100%;
    }
  }
}
