@import '../../../chrome/styles/variables';

.orderFooter {
  position: fixed;
  bottom: 0;
  width: calc(100% - 65px); //account for sidebar
  padding: 4px 0;
  background: $white;
  box-shadow: 0 2px 28px -2px #dedede;
  z-index: 105;
  .mobile {
    display: none;
  }
  .desktop {
    height: 100%;
    display: flex;
    align-items: center;
  }
  span {
    font-size: 16px;
  }
  h5 {
    display: block;
    position: relative;
    margin-left: 20px;
    font-weight: 300;
    color: $text-color;
  }
  .orderInfo {
    display: flex;
    margin-left: auto;
    margin-right: 25px;
    padding: 8px 0;
    p {
      cursor: pointer;
    }
    p:first-child {
      margin-right: 25px;
    }
    svg {
      width: 20px;
      path {
        fill: $black;
      }
    }
  }
  .badge {
    position: relative;
    bottom: 5px;
    right: 2px;
    width: 20px;
    height: 20px;
    background: $pink;
    display: inline-block;
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: bold;
    line-height: 20px;
    border-radius: 100%;
    box-shadow: $shadows;
  }
  .cta {
    button {
      font-size: 1.25rem;
      min-width: 95px;
      line-height: 25px;
      background-color: $green;
      color: $white !important;
      &:hover {
        background-color: #6de6a3 !important;
      }
      &.disabled {
        background-color: #686b72;
        cursor: not-allowed;
      }
      &.cancelOrder {
        background-color: $primary-red;
        margin-right: 10px;
        &:hover {
          background-color: #e64f47 !important;
        }
      }
    }
  }
  &.openDrawer {
    width: calc(100% - 383px);
    left: 383px;
    @media (max-width: 568px) {
      left: initial;
    }
  }
}

@media (max-width: 756px) {
  .orderFooter {
    width: 100%;
    left: 0;
    flex-wrap: wrap;
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
      height: 55px;
      z-index: 116;
    }
    .rightControls {
      margin-right: 0px !important;
      .orderNumber {
        display: none;
      }
    }
  }
}
