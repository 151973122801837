.purchaseReview {
  padding: 0 35px 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #000000;
  width: 550px;
  @media (max-width: 414px) {
    width: 100%;
  }
  .addressBlock,
  .idBlock {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 25px;
    span {
      cursor: initial;
    }
  }
}
