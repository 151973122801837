@import '../../../chrome/styles/variables';

.textInput {
  padding: 5px 10px;
}

.container {
  border-radius: $card-radius;
  padding: 5px 0;
}

.listItem {
  transition: all 0.3s ease 0s;
  user-select: none;
  cursor: pointer;
  &:hover {
    background-color: #e9ecef;
  }
}
