@import './variables';
// Colors
$white: #fff;
$gray: #6f8394;
$primaryFont: 'Dosis', 'Open Sans', sans-serif;

// Component Settings
$appBar-bg: $white;
$copyColor: $gray;
$sidebar: 65px;
$appBar: 64px;
$contentBackground: #f2f5f8;

// :global Will make styles global styles.
:global {
  *,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  button {
    font-family: $primaryFont;
    text-transform: none;
  }
  p,
  span,
  a,
  li {
    font-family: $primaryFont;
    text-transform: none;
  }

  *[contenteditable] {
    user-select: auto !important;
    p {
      user-select: text;
    }
  }
  #intercom-container .intercom-launcher-discovery-frame,
  #intercom-container .intercom-launcher-frame {
    display: none;
  }
}
.fullWidth {
  width: 100%;
  left: 0;
}
// Position the main wrapper
:global .peContainer {
  position: relative;
  top: 0;
  left: $sidebar;
  width: calc(100% - #{$sidebar});
  background: linear-gradient(to bottom, #f8f8f8, #f2f2f2);
}

.feedback {
  position: absolute;
  bottom: 15px;
  width: 100%;
  @media (max-width: 568px) {
    position: relative;
    bottom: initial;
    width: auto;
    display: flex;
  }
}
#fullWidth {
  width: 100vw;
  left: 0;
}
.hideSidebar {
  width: 100vw !important;
  left: 0 !important;
  padding-top: 0;
  overflow: hidden;
}
.noSidebar {
  width: 100vw !important;
  left: 0 !important;
  padding-top: 0;
}
.search {
  position: fixed;
  top: 0;
  right: 10px;
  z-index: 1093;
}
.sideBar {
  position: fixed; // Sticky Sidebar
  left: 0;
  top: 0;
  height: 100vh;
  width: $sidebar;
  background: $white;
  box-shadow: 0 1px 20px rgba(99, 114, 130, 0.16);
  z-index: 500;
  .mobile {
    display: none;
  }
  .active {
    svg {
      fill: $primary-blue !important;
    }
  }
  a,
  #feedbackButton {
    position: relative;
    padding: 32px 0;
    display: block;
    text-align: center;
    svg {
      display: block;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      fill: $copyColor;
    }
  }
  .photo {
    display: none;
  }
  @media (max-width: 565px) {
    .photo {
      display: inline-block;
    }
    .PhotoCard {
      width: 85px;
      position: absolute;
      top: 65px;
      left: 50%;
      transform: translateX(-50%);
      &:after {
        content: '';
        z-index: 0;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        position: absolute;
        left: 5px;
        bottom: 12px;
        background: rgba(231, 231, 231, 0.8);
      }
      span {
        z-index: 1;
        position: absolute;
        left: 10px;
        width: 55px;
        height: 55px;
        border-radius: 100%;
        position: absolute;
        bottom: 17px;
        background-color: $purple;
      }
      svg {
        height: 25px;
        width: 35px;
        left: 48%;
      }
    }
  }

  .settings {
    position: absolute;
    bottom: 0;
    left: 30px;
    svg {
      width: 30px;
    }
  }
}

// Main Chrome Media Queries
@media (max-width: 756px) {
  :global .peContainer {
    left: 0;
    width: 100%;
    min-height: calc(100vh - #{$sidebar});
  }
  .hideSidebar {
    height: 100vh !important;
  }
  .feedback {
    width: 100px;
    display: flex;
    a {
      width: 77px !important;
    }
  }
  .sideBar {
    top: initial;
    bottom: 0;
    height: auto;
    width: 100vw;
    text-align: center;
    z-index: 115;
    display: flex;
    flex-wrap: nowrap;
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
      a {
        position: relative;
        top: 0;
        left: 0;
        padding: 0;
        svg {
          position: relative;
          transform: translate(0, 0);
          top: 0;
          left: 0;
        }
      }
    }
    .settings {
      position: relative;
      display: inline;
      bottom: initial;
      left: initial;
    }
  }
}

.centeredLoader {
  position: absolute !important;
  top: 45%;
  left: calc(50vw - 95px);
}

.cardNav {
  display: flex;
  width: 100%;
  padding: 0 40px;
  position: absolute;
  > div {
    margin-left: auto;
  }
}

.confirmWrapper {
  width: 100vw;
  z-index: 90000;
  transform: translateX(0px);
}
