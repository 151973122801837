$horizontalFullHeight: 300px;
$verticalFullHeight: 500px;

$horizontalMidHeight: 200px;
$verticalMidHeight: 300px;

$horizontalSmallHeight: 170px;

.confirmMessage {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.body {
  display: flex;
  flex-direction: column;
  .selectedMenu {
    display: flex;
    justify-content: space-between;
  }
}

.cardImageHorizontal {
  height: $horizontalFullHeight;
  width: auto;
  @media (max-width: 516px) {
    height: $horizontalMidHeight !important;
    width: auto !important;
  }
  @media (max-width: 367px) {
    height: $horizontalSmallHeight !important;
    width: auto !important;
  }
}
.cardImageVertical {
  height: $verticalFullHeight;
  width: auto;
  @media (max-width: 516px) {
    height: $verticalMidHeight !important;
    width: auto !important;
  }
}
.tabContainerHorizontal {
  min-height: $horizontalFullHeight;
  @media (max-width: 516px) {
    min-height: $horizontalMidHeight !important;
  }
  @media (max-width: 367px) {
    min-height: $horizontalSmallHeight !important;
  }
}
.tabContainerVertical {
  min-height: $verticalFullHeight;
  @media (max-width: 516px) {
    min-height: $verticalMidHeight;
  }
}
.confirmContainer {
  width: 500px;
  max-height: 100%;
  @media (max-width: 600px) {
    width: 95%;
  }
  .confirmContainer {
    @media (max-width: 367px) {
      height: 100% !important;
      overflow: auto !important;
    }
  }
}

.confirmWrapper {
  z-index: 1;
}
