.accountWrapper {
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  padding: 0 1.6rem;
  .column {
    display: flex;
    flex-direction: column;
    padding: 1rem 0 0.75em;
    width: calc(40% - 0.75em);
  }
  .column:first-child {
    padding-right: 0.75em;
    padding-left: 0.75rem;
    width: calc(60% - 0.75em);
  }
}

.accountActivePlan {
  display: flex;
  flex-direction: column;
  .flex {
    display: flex;
  }
  .planTitle {
    font-weight: bold;
  }
  .subscriptionDate {
    margin-right: 30px;
    h6 {
      white-space: nowrap;
      font-size: 1.2rem;
      line-height: 1.4rem;
      font-weight: 600;
    }
    span {
      font-size: 1.4rem;
    }
  }
  .cancel {
    margin-right: 0px;
    color: red;
    font-weight: 400;
    white-space: initial;
    padding-right: 30px;
  }
  .status {
    display: flex;
    padding-top: 15px;
    &.progress {
      flex-direction: column;
    }
    .details {
      display: flex;
      width: 100%;
      > p {
        font-weight: bold;
      }
    }
  }
  .amount {
    margin-left: auto;
    padding-right: 30px;
    display: flex;
    align-items: center;
  }
}
.subscription {
  cursor: pointer;
  &:hover {
    background: #f5f5f5;
  }
}

.oneField {
  width: 100%;
}

.twoFields {
  display: flex;
  width: 100%;
}

.explainMessage {
  font-size: 1.8rem;
  color: #6f8394;
}

.userForm {
  width: 100%;
  padding-right: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  .textField {
    margin-top: 20px;

    label {
      font: inherit;
    }

    div {
      font: inherit;

      input {
        font: inherit;
      }
    }
  }
}

.saveUser {
  margin-top: 30px;
}

.successMessage {
  text-align: center;
  margin-top: 30px;
  font-size: 1.8rem;
  color: #6f8394;
}

.errorMessage {
  text-align: center;
  margin-top: 30px;
  color: red;
}

.forgotButton {
  text-align: center;
  margin-top: 30px;
  cursor: pointer;
  text-decoration: underline;
  &:hover {
    color: #e687ff;
  }
}
@media (max-width: 1140px) {
  .accountWrapper {
    flex-wrap: wrap;
    .column {
      width: 100%;
      margin: 0;
    }
    .column:first-child {
      margin: 0;
      width: 100%;
    }
  }
}
@media (max-width: 1070px) {
  .accountActivePlan {
    flex-direction: column !important;
    align-items: flex-start;
    padding-right: 30px;
    div:first-of-type {
      margin: 0 !important;
      width: 100%;
      justify-content: space-between;
      .amount {
        padding: 0;
      }
    }
    div:nth-of-type(2) {
      margin: 0 !important;
    }
  }
  .subscriptionDetails {
    flex-direction: column;
    align-items: flex-start !important;
    padding: 15px 0 !important;
    width: 100%;
    .subscriptionDate {
      width: 100%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 5px 0;
      &.cancel {
        h6 {
          width: 100%;
          padding-right: 0;
        }
      }
    }
  }
}

@media (max-width: 990px) {
  .sitePreferencesContainer {
    flex-direction: column;
    > div {
      width: 100% !important;
      &:nth-of-type(2) {
        margin-top: 25px;
      }
    }
  }
  .giftAccountContainer {
    > div {
      flex-direction: column;
    }
  }
}

.tableSpacer {
  height: 2px;
}

.sendButton {
  width: 80%;
  margin-left: 10%;
  background: #ffffffff;
  box-shadow: 0px -11px 11px 0px rgba(215, 215, 215, 1);
  border-bottom: rgb(214, 214, 214) 1px solid;
  z-index: 116;
}

@media (max-width: 767px) {
  .accountWrapper {
    padding: 0;
  }
}
