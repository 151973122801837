$primary-font: 'Source Sans Pro', sans-serif;

.container {
  height: 100%;
  width: 100%;
  z-index: 200;
  overflow: auto;
  background: #fff;

  .close {
    position: absolute;
    top: 2rem;
    right: 4rem;
    z-index: 300;
  }

  .innerContainer {
    overflow-y: auto;
    margin: auto;
    padding: 25px;
    position: relative;

    .center {
      width: 80%;
      margin: auto;
    }

    .header {
      border-top: 1px solid gray;
      padding: 1rem 0;
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      align-items: center;
      justify-content: space-between;

      h2 {
        margin-bottom: 0;
      }
    }

    section {
      padding: 1rem;
      margin-bottom: 1.5rem;

      h2 {
        font-weight: bold;
        font-size: 1.8rem;
        opacity: 0.5;
        font-family: $primary-font;
        line-height: 2.5rem;
        margin-bottom: 0.5rem;
      }
    }

    .buttons {
      display: flex;
      flex-flow: row wrap;
      width: 100%;
      align-items: center;
      justify-content: center;
      padding-bottom: 2rem;
      button {
        margin-bottom: 15px;
      }
    }
  }
}

.familyDetails {
  display: flex;
  flex-direction: column;
}

.footer {
  display: flex;
  justify-content: flex-end;
  margin: 15px 0px;
}

.actions {
  display: flex;
  margin-top: 0.75rem;

  > span {
    cursor: pointer;
    text-decoration: underline;
    font-weight: 500;
    font-size: 1.5rem;
    color: hsl(323, 100%, 51%);
    margin-right: 1.5rem;
  }
}

@media (max-width: 650px) {
  .container {
    padding: 0;
    .close {
      top: 1rem;
      right: 1rem;
      z-index: 300;
    }
    .innerContainer {
      padding: 10px 10px 65px 10px;
      .center {
        width: 100%;
        margin: auto;
      }
      .tabs {
        width: 100%;
        margin: 0 5px;
      }
    }
  }
}
