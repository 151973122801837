.floatingMenu {
  display: flex;
  position: fixed;
  bottom: 75px;
  right: 45px;
  z-index: 106;
  padding-right: 3em;
  @media (max-width: 411px) {
    z-index: 995;
  }
  align-items: flex-end;
  .helpMenu {
    .menuItems {
      display: none;
      flex-direction: column;
      align-items: center;
      > div {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 65px;
        height: 65px;
        border-radius: 100%;
        background: white;
        box-shadow: 0 4px 8px 0 #e4e5e5;
        margin-bottom: 18px;
        opacity: 0;
        transition: opacity 0.5s ease;
        opacity: 1;
        position: relative;
        &:nth-of-type(2) {
          transition-delay: 0.45s;
        }
        &:hover {
          cursor: pointer;
          box-shadow: 0 4px 8px 0 #ccc;
          p {
            font-weight: bold;
          }
        }
        svg {
          position: relative;
          top: 5px;
        }
        p {
          position: relative;
          top: 2px;
          color: #333;
          font-size: 14px;
          line-height: 1.5rem;
        }
      }
    }
    .mainButton {
      width: 65px;
      height: 65px;
      border-radius: 100%;
      background: #fff;
      box-shadow: 0 4px 8px 0 #e4e5e5;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-image: linear-gradient(115deg, #ff5689, #e687ff) !important;
      box-shadow: 0 1px 15px 0 rgba(239, 146, 251, 0.3) !important;
      svg {
        position: relative;
        top: 4px;
        fill: #fff;
      }
      p {
        position: relative;
        top: 2px;
        font-size: 14px;
        color: #fff;
      }
    }
    &.open {
      .mainButton {
        margin-top: 4px;
      }
      .menuItems {
        display: flex;
        > div {
          opacity: 1;
        }
      }
    }
  }
  .intercomLauncher {
    position: relative;
    margin-left: 16px;
    z-index: 2147483003;
    width: 65px;
    height: 65px;
    border-radius: 50%;
    background: #00bac6;
    cursor: pointer;
    box-shadow: 0 1px 15px 0 rgba(239, 146, 251, 0.3) !important;
    .intercomOpenBtn {
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;
      transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
      opacity: 1;
      transform: rotate(0deg) scale(1);
      svg {
        width: 28px;
        height: 32px;
      }
      svg path {
        fill: #fff;
      }
    }
    .intercomCloseBtn {
      display: flex;
      -moz-box-align: center;
      align-items: center;
      -moz-box-pack: center;
      justify-content: center;
      position: absolute;
      top: 0px;
      bottom: 0px;
      width: 100%;
      transition: transform 0.16s linear 0s, opacity 0.08s linear 0s;
      opacity: 0;
      transform: rotate(-60deg);
      svg {
        width: 16px;
      }
      svg path {
        fill: #fff;
      }
    }
    &.intercomOpen {
      .intercomOpenBtn {
        opacity: 0;
        transform: rotate(30deg) scale(0);
      }
      .intercomCloseBtn {
        opacity: 1;
        transform: rotate(0deg);
      }
    }
  }
}
