.progressTitle {
  width: 100%;
  text-align: center;
  h1 {
    font-size: 26px;
  }
}
.circularProgress {
  color: #00e676;
}

.body {
  display: flex;
  justify-content: center;
  padding: 25px 0;
}
.errors {
  border-top: 1px solid rgba(175, 175, 175, 0.33);
  .errorTitle {
    width: 100%;
    text-align: center;
    font-size: 20px;
    color: #dd2476;
  }
  .errorList {
    padding: 0 125px;
  }
}
.actions {
  display: flex;
  justify-content: flex-end;
  margin-right: 20px;
}
