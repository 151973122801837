.paymentMethodWrapper {
  margin: 20px 0;
  h5 {
    text-align: left;
  }
}

.multiPayments {
  display: flex;
  align-items: center;
  margin-bottom: 35px;
  > div {
    margin-bottom: 0;
  }
}
.isMixedOrder {
  margin: 35px 0;
}

.sectionTitle {
  padding-top: 20px;
  border-bottom: 1px solid #333;
  padding-bottom: 10px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  h5 {
    font-family: 'Dosis';
    color: rgba(0, 0, 0, 0.68);
    font-weight: 500;
    text-transform: uppercase !important;
    font-size: 16px;
    letter-spacing: 0.85px;
  }
}

@media (max-width: 1450px) {
  .multiPayments {
    flex-wrap: wrap;
    > div {
      width: 250px;
      display: flex;
      justify-content: center;
    }
    span {
      margin: 8px 0 !important;
      text-align: center;
      display: block;
      width: 250px;
    }
  }
}
