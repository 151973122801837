.priceAndButtonWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  @media (max-width: 568px) {
    display: block;
  }
  .priceContainer {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: space-around;
    justify-content: center;
    flex-direction: column;
    .priceLabel {
      text-transform: uppercase !important;
      letter-spacing: 0.155rem;
      font-size: 1.1rem;
      font-weight: 800;
      color: #797878;
    }
    .price {
      font-size: 35px;
      font-weight: 700;
      color: #646464;
      @media (max-width: 1400px) {
        font-size: 24px;
      }
    }
  }
  .selectGiftButtonContainer {
    display: flex;
    justify-content: center;
    align-items: bottom;
    @media (max-width: 568px) {
      margin: 20px 0;
    }
    .selectGiftButton {
      width: 218px;
      font-size: 15px;
    }
  }
}

.tabsContainer > div {
  @media (max-width: 568px) {
    overflow-x: auto !important;
  }
}

@media (max-width: 500px) {
  .selectGiftButtonContainer {
    .selectGiftButton {
      width: 150px !important;
    }
  }
  .price {
    font-size: 18px !important;
  }
}

@media (max-width: 376px) {
  .selectGiftButtonContainer {
    .selectGiftButton {
      width: 150px !important;
    }
  }
  .price {
    font-size: 18px !important;
  }
}
