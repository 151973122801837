@import '../../../../chrome/styles/variables';

.container {
  h5 {
    letter-spacing: 1px;
    padding: 15px 0;
  }
  a {
    color: $blue;
  }
  .errors {
    display: flex;
    flex-direction: column;
    border: 1px solid #ff5689;
    background-color: rgba(255, 86, 137, 0.3);
    padding: 15px;
    h6 {
      letter-spacing: 1px;
      font-size: 20px;
    }
    span {
      font-size: 15px;
      padding: 5px 30px;
      display: list-item;
      list-style-position: inside;
    }
  }
  .userForm {
    background: #fff;
    padding: 20px;

    .inputLabel {
      color: rgba(66, 66, 66, 0.6);
    }

    .identification {
      margin-top: -25px;
      display: flex;
      flex-direction: column;
    }
  }
  .accept {
    display: flex;
    padding-top: 60px;
  }
  .submit {
    display: flex;
    justify-content: flex-end;
  }
}

.purchaseReview {
  padding: 0 35px 25px;
  margin-bottom: 25px;
  border-bottom: 1px solid #000000;
  width: 550px;
  @media (max-width: 414px) {
    width: 100%;
  }
  .addressBlock,
  .idBlock {
    display: flex;
    flex-direction: column;
    text-align: left;
    padding-top: 25px;
    span {
      cursor: initial;
    }
  }
}

.affiliateInfo {
  padding: 25px;
  text-align: center;
  h6 {
    margin-bottom: 15px;
  }
  a {
    padding: 15px;
  }
}
