@import '../styles/variables';
$breakpoint-alpha: 784px;

.rwdTable {
  margin: 0 0 1em 0;
  min-width: 99%;
  table-layout: fixed;
  border-spacing: 0;
  margin-left: auto;
  margin-right: auto;
  .rwdHeader {
    tr {
      background: transparent;
      th {
        font-size: 1.7rem;
        font-weight: 500;
      }
    }
  }
  tr {
    &.rwdRow {
      background-color: $white;
      border-radius: 0;
      box-shadow: 0 4px 5px 0 #e0e0e0;
      &:after {
        content: ' ';
        width: 100%;
        display: block;
        height: 25px;
      }
    }
  }
  th {
    display: none;
  }
  td {
    display: block;
    font-size: 17px;
    &:first-child {
      padding-top: 0.5em;
      border-radius: 0;
    }
    &:last-child {
      padding-bottom: 0.5em;
      border-radius: 0;
    }
    &:before {
      content: attr(data-th) ': ';
      font-weight: bold;
      width: 6.5em;
      display: inline-block;
      @media (min-width: $breakpoint-alpha) {
        display: none;
      }
    }
  }

  th,
  td {
    text-align: left;
    @media (min-width: $breakpoint-alpha) {
      display: table-cell;
      padding: 0.25em 0.5em;
      &:first-child {
        padding-left: 0;
      }
      &:last-child {
        padding-right: 0;
      }
    }
  }
  tr {
    border-color: lighten(#34495e, 10%);
  }
  th,
  td {
    margin: 0.5em 1em;
    @media (min-width: $breakpoint-alpha) {
      padding: 1.58em;
    }
  }
  th,
  td:before {
    color: $pink;
    font-size: 12px;
  }
}
