@import './variables';
// scss-lint:disable VendorPrefix
@mixin typo-preferred-font($use-preferred: true) {
  @if $use-preferred {
    font-family: $preferred-font;
  }
}

@mixin typo-display-3($color-contrast: false, $use-preferred: true) {
  @include typo-preferred-font($use-preferred);
  font-size: $unit * 5.6;
  font-weight: 400;
  line-height: 1.35;
  letter-spacing: -0.02em;

  @if $color-contrast {
    opacity: 0.54;
  }
}

@mixin typo-display-2($color-contrast: false, $use-preferred: true) {
  @include typo-preferred-font($use-preferred);
  font-size: $unit * 4.5;
  font-weight: 400;
  line-height: $unit * 4.8;

  @if $color-contrast {
    opacity: 0.54;
  }
}

@mixin typo-display-1($color-contrast: false, $use-preferred: true) {
  @include typo-preferred-font($use-preferred);
  font-size: $unit * 3.4;
  font-weight: 400;
  line-height: $unit * 4;

  @if $color-contrast {
    opacity: 0.54;
  }
}

@mixin typo-headline($color-contrast: false, $use-preferred: true) {
  @include typo-preferred-font($use-preferred);
  font-size: $unit * 2.4;
  font-weight: 400;
  line-height: $unit * 3.2;
  -moz-osx-font-smoothing: grayscale;

  @if $color-contrast {
    opacity: 0.87;
  }
}

@mixin typo-title($color-contrast: false, $use-preferred: true) {
  @include typo-preferred-font($use-preferred);
  font-size: $unit * 2;
  font-weight: 500;
  line-height: 1;
  letter-spacing: 0.02em;

  @if $color-contrast {
    opacity: 0.87;
  }
}

@mixin typo-subhead($color-contrast: false, $use-preferred: true) {
  @include typo-preferred-font($use-preferred);
  font-size: $unit * 1.6;
  font-weight: 400;
  line-height: $unit * 2.4;
  letter-spacing: 0.04em;

  @if $color-contrast {
    opacity: 0.87;
  }
}

@mixin typo-body-1($color-contrast: false, $use-preferred: false) {
  @include typo-preferred-font($use-preferred);
  font-size: $unit * 1.4;
  font-weight: 400;
  line-height: $unit * 2.4;
  letter-spacing: 0;

  @if $color-contrast {
    opacity: 0.87;
  }
}
