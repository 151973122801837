.searchField {
  width: 750px;
  margin: 0 auto;
  position: relative;
  top: -30px;

  .searchSelectIcon {
    top: 0;
  }
}

//Search Tags
.searchTags {
  width: 100%;
  display: flex;
  flex-wrap: nowrap;
  overflow-x: auto;
  overflow-y: hidden !important;
  justify-content: space-evenly;

  > div {
    flex: 0 0 auto;
    padding-bottom: 10px;
    cursor: pointer;
    font-size: 15px;
    font-weight: 400;
    color: #6f8394;
  }
}

.buildYourOwn {
  background-image: linear-gradient(115deg, #02c3e2, #02c3e2) !important;
  box-shadow: 0 2px 9px -4px rgb(23, 130, 234);
  width: 160px !important;
  height: 40px !important;
  transition: 0.3s box-shadow ease-in-out;

  &:hover {
    box-shadow: none;
  }
}

.filterMenu {
  position: absolute !important;
  right: 0;
  top: 0;
  height: 45px !important;
  background-color: #fff;
  box-shadow: 0 4px 2px -2px #d4d4d4;
  @media (max-width: 400px) {
    width: 100% !important;
    top: 46px;
  }
}

// Search Bar
.search {
  position: relative;
  display: flex;
  align-items: center;
  background: white;
  padding: 13.5px 10px 13.5px 10px;
  min-height: 45.4px;
  box-shadow: 0 4px 2px -2px #d4d4d4;
  margin: 5px 35px;
  border-radius: 2px;

  .selectedTag {
    background: linear-gradient(115deg, #e687ff, #ff5689);
    color: #e687ff;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: 9px;
    font-weight: 500;
    flex: 0 0 auto;
  }

  input[type='text'] {
    height: auto;
    flex: 1 1 auto;
    position: relative;
    padding: 0 10px 0 9px;
    background-color: transparent;
    border: none;
    color: #9c9c9c;
    font-family: 'Montserrat', sans-serif;
    font-size: 16px;
    font-weight: 400;
    z-index: 0;
  }
}

@media (max-width: 767px) {
  .searchField {
    width: 100%;
    left: 0;
    transform: none;
  }
  .search {
    margin: 5px 15px;
  }
  .searchTags {
    justify-content: initial !important;

    > div {
      margin: 0 15px;

      &:last-of-type {
        &:after {
          content: '';
          padding: 8px;
        }
      }
    }
  }
}
