.paymentTab {
  display: flex;
  justify-content: center;
  background: #fff;
  width: 100%;
  max-width: 280px;
  height: 65px;
  padding: 5px;
  box-shadow: 0 2px 11px 0px #b5b5b5;

  .paymentTabContents {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    p {
      margin-left: 5px;
      color: gray;
      font-weight: 500;
      letter-spacing: 0.65px;
    }
    &.card {
      background-image: linear-gradient(115deg, #ff5689, #e687ff);
      flex-flow: row wrap;
      justify-content: flex-end;
      p {
        color: #fff;
      }
    }
  }
}
