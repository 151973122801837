.featureGridContainer {
  background: #fff;
  width: 100%;
  padding: 50px 0;
}
.offWhite {
  background: none;
}
.featureGrid {
  display: flex;
  flex: 1 1 auto;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 90%;
  margin: 0 auto;
  h1 {
    width: 100%;
    font-size: 1.5em;
    margin-bottom: 55px;
  }
  .featurePlanRow {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-between;
    margin-bottom: 100px;
  }
  .featureRow {
    display: flex;
    flex: 1 1 auto;
    justify-content: space-evenly;
    margin-bottom: 50px;
  }
  .planFeature {
    width: 50%;
    margin: 0 4em;
  }
}
.disclaimer {
  h6 {
    padding: 25px 50px;
    background: #fff;
  }
}

@media (max-width: 1430px) {
  .featureGrid {
    width: 100%;
    padding: 0 15px;
  }
}

@media (max-width: 1290px) {
  .featureGrid {
    .featureRow {
      justify-content: initial;
      flex-wrap: wrap;
      margin-bottom: 50px;
    }
    .planFeature {
      margin: 0 2em;
    }
  }
}

@media (max-width: 1029px) {
  .featureGrid {
    h1 {
      text-align: center;
    }
    .featureRow {
      justify-content: center;
    }
  }
}
@media (max-width: 785px) {
  .featureGrid {
    .featurePlanRow {
      flex-wrap: wrap;
      justify-content: center;
      margin-bottom: 50px;
    }
    .planFeature {
      margin-left: 0;
      margin-right: 0;
      width: 80%;
      margin-bottom: 50px;
    }
  }
}
