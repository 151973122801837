.splitter {
  padding: 15px 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: inherit;
  box-shadow: 0 1px 5px 0 #c2c2c2;
  h1 {
    font-family: inherit;
    font-size: 1.35em;
    line-height: 1.385em;
    margin-left: 25px;
  }
  .icon {
    background: #71d475;
    padding: 12px;
    border-radius: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

@media (max-width: 767px) {
  .splitter {
    .icon {
      position: relative;
      left: 8px;
    }
  }
}
