$pink: #f26ec5;
$purple: #b0a2ff;
$green: #6df2a8;
$yellow: #ffd795;
$blue: #6db0ff;
$brightBlue: #99f6ff;
$white: #fff;
$black: #474d53;
$gray-1: #6f8394;
$gray-2: #f0f0f0;
$main-background: $white;

$primary-blue: $brightBlue;
$primary-red: $pink;
$primary-yellow: $yellow;
$text-color: $gray-1;
$header-color: $black;
// UI
$button-shadows: 0 6px 14px 0 rgba(109, 176, 255, 0.3);
$shadows: 0 2px 20px 0 rgba(0, 0, 0, 0.18);
$light-shadows: 0 8px 8px -5px #dedede;
$shadows-hover: 0 8px 22px 0 rgba(0, 0, 0, 0.3);
$block-radius: 15px;
//Tabs active color
$color-primary: $primary-red;
$box-shadow: 0 2px 20px 0 #ebeef1;
$card-shadow: 0 8px 14px -1px #dedede;
$card-radius: 4px;

$footer-height: 65px;
