.CardOptionsToggle {
  width: 100%;
  margin-bottom: 10px;
  padding-left: 25px;
  padding-right: 25px;
  padding: 10px 25px 0 25px;
  h6 {
    font-size: 13px;
    font-weight: 400;
    margin-bottom: 25px;
    display: inline-block;
    background: white;
    box-shadow: 0 1px 2px 0 #ccc;
    padding: 0 5px;
  }
  .cardConfigOptions {
    display: flex;
    flex-wrap: nowrap;
    padding-bottom: 0;
    -ms-flex-align: center;
    align-items: center;
    overflow-x: auto;
    .overScroll {
      display: flex;
      flex-wrap: wrap;
    }
  }
  .CardOptions {
    position: relative;
    display: flex;
    border-radius: 12px;
    box-shadow: 0px 2px 7px 0 #d0d3d7;
    background: #fff;
    background-image: none;
    margin: 0 12px 15px 12px;
    height: 38px;
    flex: 0 1 auto;
    min-width: 170px;
    &:hover {
      cursor: pointer;
    }
    .CardOptionsOption {
      pointer-events: none;
      width: 100%;
      height: 100%;
      border-radius: 100%;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      span {
        font-size: 13.25px;
        font-weight: 500;
      }
      .optionImageWrapper {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        margin-bottom: 0;
        position: relative;
        right: 15px;
        > div {
          display: flex;
          align-items: center;
        }
      }
      svg {
        display: block !important;
        margin: 0 auto;
      }
    }
    &.activeOption {
      background-color: #ff059f;
      box-shadow: 0 1px 15px 0 rgba(255, 5, 159, 0.3);
    }

    .CardBadge {
      top: 50%;
      right: 20px;
      border-radius: 18px;
      min-width: 36px;
      height: calc(100% - 2px);
      font-size: 0.6rem;
      background-color: #ff059f;
    }
  }
}
@media (max-width: 1100px) {
  .CardOptionsToggle {
    padding: 0;
    padding-left: 25px;
    padding-right: 25px;
    .CardOptions {
      .CardOptionsOption {
        span {
          font-size: 10px;
        }
      }
    }
  }
}
@media (max-width: 970px) {
  .CardOptionsToggle {
    margin-bottom: 0 !important;
  }
}
@media (max-width: 568px) {
  .CardOptionsToggle {
    flex-wrap: wrap;
    justify-content: center;
  }
  .CardOptionsToggle {
    .cardConfigOptions {
      width: 100%;
    }
  }
}

@media (max-width: 480px) {
  .CardOptionsToggle {
    padding-left: 10px;
    padding-right: 15px;
    .CardOptions {
      min-width: 140px;
      margin: 0 5px 15px 10px;
    }
  }
}
