.notification {
  display: flex;
  margin-bottom: 1rem;
  background-color: #fff;
  border-radius: 3px;
  box-shadow: 0 2px 3px 0 #dedede;
  align-items: center;
  padding: 10px 25px;
  p {
    font-size: 16px;
    margin: 0;
    color: #767676;
  }
}

.notificationActions {
  display: flex;
  margin-left: auto;
  > div {
    margin-right: 15px;
  }
}

@media (max-width: 767px) {
  .notification {
    flex-wrap: wrap;
    padding: 15px 25px;
  }
  .notificationActions {
    width: 100%;
    justify-content: center;
    padding-top: 15px;
  }
}
