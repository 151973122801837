$sideBar: 65px;
$mobile: 568px;

.confirmWrapper {
  display: flex;
  height: 100%;
  width: calc(100vw - #{$sideBar});
  position: fixed !important;
  top: 0 !important;
  left: $sideBar !important;
  z-index: 200;
  justify-content: center;
  align-items: center;
  @media (max-width: $mobile) {
    width: 100% !important;
    left: 0 !important;
  }
}
.confirmImage {
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    width: 100%;
  }
}
.confirmContainer {
  z-index: 201;
  width: 500px;
  max-height: 100%;
  background-color: #fff;
  overflow-x: auto;
  @media (max-width: 515px) {
    width: 95%;
    top: 0;
    left: 0;
    transform: none;
  }

  @media (max-height: 750px) {
    height: 75%;
  }

  .confirmBody {
    padding: 1.5rem;
    text-align: center;
    font-size: 16px;
    font-weight: 400;
    color: #6f8394;
  }
  .confirmActions {
    display: flex;
    > .accept,
    .decline {
      width: 50%;
      text-align: center;
      padding: 7px;
      border: none;
      cursor: pointer;
      appearance: none;
      background: none;
      transition: background-color 0.3s ease-in;
      transition: color font-weight 0.3s ease-out;
      &:hover {
        background-color: #ebecee;
        color: #ff5689;
        font-weight: 500;
      }
    }
  }
}
.backdrop {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 200;
  background-color: rgba(48, 57, 63, 0.88);
}
