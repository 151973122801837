.emptyTarget {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.notificationsWrapper {
  padding: 3rem;
}

@media (max-width: 430px) {
  .notificationsWrapper {
    padding: 10px;
  }
}
