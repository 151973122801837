@import './variables';
@import './mixins';

body {
  position: absolute;
  width: 100%;
  height: 100%;
  padding: 0;
  margin: 0;
  font-family: 'Dosis', sans-serif;
  font-size: 1.6rem;
}

a,
abbr,
address,
article,
aside,
audio,
b,
blockquote,
body,
caption,
cite,
code,
dd,
del,
dfn,
dialog,
div,
dl,
dt,
em,
fieldset,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
hr,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
p,
pre,
q,
samp,
section,
small,
span,
strong,
sub,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
ul,
var,
video {
  padding: 0;
  margin: 0;
  border: 0;
  outline: 0;
}

*,
*:before,
*:after {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6,
label,
p,
button,
abbr,
a,
span,
small {
  text-size-adjust: 100%;
}

a {
  text-decoration: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

input:not([type='checkbox']):not([type='radio']),
button {
  outline: none;
  appearance: none;
  -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
}
::-webkit-input-placeholder {
  /* Chrome/Opera/Safari */
  color: #6f8394;
}
::-moz-placeholder {
  /* Firefox 19+ */
  color: #6f8394;
}
:-ms-input-placeholder {
  /* IE 10+ */
  color: #6f8394;
}
:-moz-placeholder {
  /* Firefox 18- */
  color: #6f8394;
}
input:not([type='checkbox']):not([type='radio']),
textarea {
  color: #6f8394;
}

label,
.label {
  color: #526270;
}

// -- Remove firefox default style for required inputs
input[required]:-moz-ui-invalid {
  box-shadow: none;
}

// -- Material design font sizes
h1 small,
h2 small,
h3 small,
h4 small,
h5 small,
h6 small {
  @include typo-display-3($color-contrast: true);
}

h1 {
  @include typo-display-3;
}

h2 {
  @include typo-display-2;
}

h3 {
  @include typo-display-1;
}

h4 {
  @include typo-headline;
}

h5 {
  @include typo-title;
}

h6 {
  @include typo-subhead;
}

p {
  @include typo-body-1;
}

iframe[name='us-entrypoint-button'] {
  top: 15%;
}
