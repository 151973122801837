$font-stack: 'Open Sans', sans-serif;
.modalWindow {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  background: rgba(48, 57, 63, 0.88);
  z-index: 104;
}
.modal {
  display: flex;
  flex-direction: column;
  position: fixed;
  overflow-x: auto;
  top: 50%;
  left: 50%;
  max-width: 1080px;
  transform: translate(calc(-50% - (4% * -1)), -50%);
  z-index: 105;
  background: #fff;
  min-height: 450px;
  padding: 10px 15px 5px 15px;
  .campaignHeader {
    display: flex;
    flex-direction: row;
    h2 {
      margin: 15px 0px 25px 0px;
      position: relative;
      left: calc(50% - 60px);
    }
  }
  .cardAndDetailsContainer {
    display: flex;
    justify-content: space-evenly;
  }
  .sideScrollContainer {
    padding: 0px 15px 15px 15px;
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 15px;
    .scrollable {
      overflow: auto;
      display: flex;
      flex-direction: row;
    }
  }
  .previewContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-left: 15px;
  }
  .card {
    .portrait {
      width: 388px;
      height: 532px;
    }
    .landscape {
      width: 532px;
      height: 388px;
    }
  }
  .rightPanel {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-left: 15px;
    padding: 20px 35px;
    .content {
      display: flex;
      flex-direction: column;
    }
    .campaignDetails {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      width: 100%;
      min-width: 300px;
      font-size: 24px;
      font-family: $font-stack;
      > h4:after {
        content: '';
        width: 30px;
        height: 1px;
        display: block;
        background: #b3b3b3;
        margin-top: 5px;
      }
      .subHeader {
        padding: 30px 0;
        font-size: 2rem;
        letter-spacing: 0.5px;
        font-family: $font-stack;
      }
      .price {
        padding-left: 55px;
        font-size: 24px;
        font-weight: bold;
      }
    }
    .cardDetails {
      margin-top: 30px;
      display: flex;
      flex-direction: column;
      > h4:after {
        content: '';
        width: 30px;
        height: 1px;
        display: block;
        background: #b3b3b3;
        margin-top: 5px;
      }
      .cardDescription {
        display: flex;
        flex-direction: column;
        padding: 15px 0;
        span {
          font-family: $font-stack;
          letter-spacing: 0.5px;
        }
      }
    }
    .footer {
      .samplePack {
        padding: 22px 0;
        > span {
          padding: 5px;
          margin-left: 3px;
          &:hover {
            padding: 5px;
            background: #eaeaea;
          }
        }
      }
      .actions {
        display: flex;
      }
    }
  }
}

.panelImage {
  height: 450px;
}

@media (max-height: 870px) {
  .modal {
    height: 100%;
  }
  .cardsContainer {
    height: calc(100% - 200px);
  }
}

@media (max-width: 1370px) {
  .previewContainer {
    .card {
      width: 550px;
    }
  }
  .panelImage {
    height: 400px;
  }
  .rightPanel {
    padding: 10px 15px !important;
  }
}

// media queries
@media (max-width: 1095px) {
  .modal {
    width: 910px;
    max-height: 100vh;
  }
  .panelImage {
    height: 355px;
  }
}

@media (max-width: 986px) {
  .modal {
    width: 560px;
  }
  .cardsContainer {
    flex-direction: column !important;
    justify-content: center !important;
  }
  .cardAndDetailsContainer {
    flex-direction: column;
    justify-content: center;
  }
  .actions {
    position: relative !important;
    justify-content: flex-end !important;
  }
  .rightPanel {
    padding: 0px 0px !important;
  }
}

@media (max-width: 580px) {
  .modal {
    width: 100%;
    transform: initial !important;
    top: 0;
    left: 0;
    height: 100%;
  }
  .cardsContainer {
    height: 100%;
    overflow-y: auto;
  }
  .previewContainer {
    margin-right: 15px;
  }
  .panelImage {
    height: 290px;
  }
  .cardAndDetailsContainer {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .campaignHeader {
    height: 300px;
  }
}

@media (max-width: 430px) {
  .panelImage {
    height: 210px;
  }
}

@media (max-width: 320px) {
  .panelImage {
    height: 173px;
  }
}
// media queries ^^^^^^^^

// animation
.enter {
  opacity: 0.01;
  transform: translateY(-200px);
}

.enter.enterActive {
  opacity: 1;
  transition: 200ms;
  transform: translateY(-200px);
}

.leave {
  opacity: 1;
  transform: translateY(0px);
}

.leave.leaveActive {
  opacity: 0.01;
  transition: 200ms;
  transform: translateY(-200px);
}

.appear {
  opacity: 0.01;
  transform: translateY(-200px);
}

.appear.appearActive {
  opacity: 1;
  transition: 200ms;
  transform: translateY(-200px);
}
// animation ^^^^^^^^^^^^^^^^^^
